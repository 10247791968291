import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { ChevronDownSmallIcon } from '@naan/icons/chevron-down-icon';
import { ChevronRightSmallIcon } from '@naan/icons/chevron-right-icon';
import { styled } from '@stitches/react';
import classNames from 'classnames';
import * as React from 'react';

const Wrapper = styled('div', {
  border: '1px solid $gray-100',
  padding: '8px',
  borderRadius: '6px',
  marginTop: 20,
  '& .trigger': {
    display: 'flex',
    gap: 4,
    flexDirection: 'row',
    cursor: 'pointer',
    padding: 12,
    position: 'sticky',
    top: 0,
    background: '$white',
    borderRadius: 4,
    '& .icon': {
      width: 24,
      aspectRatio: '1',
      color: '$black-alpha-30',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '&:hover': {
      background: '$blue-20',
    },
  },
  '&:has(.expanded) .trigger': {
    borderBottom: '1px solid $gray-100',
  },
  '& .expandable': {
    display: 'grid',
    gridTemplateRows: '0fr',
    transition: 'grid-template-rows .3s',
    '& > div.expandable-inner': {
      gridRow: '1 / span 2',
      overflow: 'hidden',

      '& .expandable-content': {
        padding: 12,
      },
    },

    '&.expanded': {
      gridTemplateRows: '1fr',
    },
  },
});

export const Disclosure: React.FC<{ label: React.ReactNode }> = ({
  children,
  label,
}) => {
  const zippy = useSwitch2(false);

  return (
    <Wrapper>
      <div className="trigger" onClick={zippy.toggle}>
        <span className="icon">
          {zippy.value ? <ChevronDownSmallIcon /> : <ChevronRightSmallIcon />}
        </span>
        {label}
      </div>
      <div
        className={classNames('expandable', {
          expanded: zippy.value,
        })}
      >
        <div className="expandable-inner">
          <div className="expandable-content">{children}</div>
        </div>
      </div>
    </Wrapper>
  );
};
