import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { AppFactory } from 'app/app-factory';
import { elementIdToDomId } from '@tikka/elements/element-id-utils';
import { ElementNode } from '@tikka/client/client-aliases';
import { observer } from 'mobx-react';
import { Sentence as SentenceElement } from '@tikka/client/client-aliases';
import { StyledSentence } from 'player/views/elements-presentations/sentence-presentation';
import { Text } from '@naan/primitives/text';
import { usePlayerModel } from 'player/views/player-model-context';
import { VSpacer } from '@naan/primitives/spacer';
import { VStack } from '@naan/primitives/stack';
import { Word } from './word-element';
import { scrollToCurrentLine } from 'study/views/fx/scrolling';
import { useLineReconciler } from '../player-ui-model-setup';
import { DialogHtmlContainer } from 'components/ui/dialog-html-container';
import { bugsnagNotify } from '@app/notification-service';
import { BasePlayerModel } from 'player/models/base-player-model';
import { SentenceId } from '@tikka/basic-types';

import __ from '@core/lib/localization';
import { computed } from 'mobx';
import { RedactionMode } from 'player/models/redaction-modes';
import { PlayerKind } from '@common/misc-types';

function presentSkipModal(model: BasePlayerModel, sentenceId: SentenceId) {
  AppFactory.dialogPresenter.present(onDismiss => {
    return (
      <SkipToSentenceConfirmationDialog
        onDismiss={() => {
          onDismiss();
          /// @elliottjf, @frank this is not in the spec, but felt good to me as a cancel action
          scrollToCurrentLine();
        }}
        okAction={() => {
          model.sentenceSelect(sentenceId);
        }}
      />
    );
  });
}

const SkipToSentenceConfirmationDialog = ({
  onDismiss,
  okAction,
}: {
  onDismiss: () => void;
  okAction: () => void;
}) => {
  const handleOkAndDismiss = React.useCallback(() => {
    onDismiss();
    okAction();
  }, [okAction, onDismiss]);

  return (
    <DialogHtmlContainer onDismiss={onDismiss}>
      <Dialog.HtmlHeading>
        {__('Skip to sentence', 'skipToSentenc')}
      </Dialog.HtmlHeading>
      <Dialog.Body>
        <VStack>
          <Text>
            {__(
              'This will mark all previous sentences as played',
              'thisWillMarkAllPrevious'
            )}
          </Text>
          <VSpacer size={1} />
        </VStack>
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          presentation={'cancel'}
          label={__('Cancel', 'cancel')}
          data-test-id="cancelButton"
          onClick={onDismiss}
          tabIndex={-1}
        />
        <Dialog.Button
          presentation={'teal'}
          label={__('Skip forward', 'skipForwar')}
          data-test-id="okButton"
          onClick={handleOkAndDismiss}
          autoFocus
        />
      </Dialog.ButtonsContainer>
    </DialogHtmlContainer>
  );
};

export const Sentence = observer(({ node }: { node: ElementNode }) => {
  const model = usePlayerModel();
  const reconciler = useLineReconciler();
  if (!reconciler) {
    bugsnagNotify('Sentence - missing reconciler');
    return null;
  }

  const { children } = node;
  const sentenceId = node.element.id;
  const sentenceElement = node.element as SentenceElement;
  const redactionComputed = computed(() =>
    model.getSentenceRedactionMode(sentenceId)
  );
  const redactionMode = () => redactionComputed.get();
  const listenMode = model.playerKind === PlayerKind.LISTEN;

  const handleSentenceClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      // const target = e.target as HTMLElement;

      // if (!target.classList.contains('l2')) {
      //   return;
      // }

      const {
        player: { transportState },
        // playActionDisabled,
      } = model;
      const isPlaying = transportState.isPlaying;
      const isBeyond = model.sentenceIsBeyondFurthest(sentenceId);
      const isCurrent = model.sentenceIsCurrent(sentenceId);
      // const {
      //   player: { transportState },
      //   playActionDisabled,
      // } = model;

      if (isBeyond) {
        if (!isPlaying) {
          if (!model.skipForwardAllowed) {
            return;
          }
          presentSkipModal(model, sentenceId as SentenceId);
        }
      } else {
        if (isCurrent) {
          // console.log('sentence is current');
          if (isPlaying) {
            model.playPauseAction();
          } else {
            model.sentenceSelect(sentenceId);
            // model.unredactCurrentSentence();
          }
        } else {
          if (isPlaying && model.sentenceIsVisited(sentenceId)) {
            model.pause();
          }
          model.sentenceSelect(sentenceId);
        }
      }
    },
    [model, sentenceId]
  );

  const handleUnredaction = React.useCallback(() => {
    model.unredactCurrentSentence();
  }, [model]);

  const childComponents = children.map((node: ElementNode) => (
    <Word key={node.element.id} node={node} redaction={redactionMode} />
  ));

  // let className = reconciler.getJoinedMembershipStringForElement(sentenceId);
  // const memberships = reconciler.getMembershipForElement(sentenceId);
  // if (model.translationsShown) {
  //   memberships.push('show-translations');
  // }
  // const className = memberships.join(' ');

  let className = reconciler.getJoinedMembershipStringForElement(sentenceId);

  if (
    model.forceUnredactCurrentSentenceId ||
    redactionMode() === RedactionMode.SHOW_ALL ||
    listenMode
  ) {
    className += ' hide-show-words-btn';
  }

  // TODO right now this is causing all sentences to renderer to set this CSS class
  if (model.translationsShown) {
    className += ' show-translations'; // beware leading space
  }

  const elementDomId = elementIdToDomId(null, sentenceId);

  return (
    <>
      <StyledSentence
        children={childComponents}
        id={elementDomId}
        // className={'pending-pause ' + className}
        className={className}
        onClick={handleSentenceClick}
        onUnredact={handleUnredaction}
        translation={sentenceElement.translation}
        sentenceId={sentenceId as SentenceId}
      />
    </>
  );
});
