import * as React from 'react';
import { SentenceId } from '@tikka/basic-types';
import { observer } from 'mobx-react';
import { SoundbiteModel } from 'soundbite/models/soundbite-model';

import {
  TranslationButton,
  ReplayButton,
  OverflowButton,
  SnailButton,
  CopyButton,
} from './sentence-toolbar-buttons';
import { ToolbarStyles } from './toolbar-styles';
import { Tooltip } from '@naan/primitives/tooltip';
import { Box } from '@naan/primitives/box';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { useOnClickOutside } from '@common/hooks/use-onclick-outside';

import __ from '@core/lib/localization';
import { positionExpandedSentence } from 'study/views/fx/scrolling';
import { AppFactory } from '@app/app-factory';

const TranslationButtonWithTip: React.FC<{}> = observer(() => {
  const tipSwitch = useSwitch2(false);
  const ref = React.useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    tipSwitch.off();
  });

  return (
    <div ref={ref}>
      <Tooltip
        tipContent={
          <Box
            css={{
              textAlign: 'left',
              textStyle: 'small-text',
            }}
          >
            {__(
              'Translation will be enabled after answer is revealed',
              'translationWillBeEnabledAfterAnswerIsRevealed'
            )}
          </Box>
        }
        showTip={tipSwitch.value}
        placement={'top'}
      >
        <TranslationButton
          active={false}
          disabled={true}
          action={() => tipSwitch.toggle()}
        />
      </Tooltip>
    </div>
  );
});

const SoundbiteOnlyButtons: React.FC<{
  model: SoundbiteModel;
  sentenceId: SentenceId;
}> = observer(({ model, sentenceId }) => {
  const showTranslation =
    model.shouldDisplaySentenceInlineTranslation(sentenceId);
  const onShowTranslation = () => {
    model.toggleCurrentSentenceInlineTranslation();
    positionExpandedSentence();
  };

  if (model.answerRevealed) {
    return (
      <TranslationButton
        active={showTranslation}
        action={onShowTranslation}
        disabled={!model.answerRevealed}
      />
    );
  }

  return <TranslationButtonWithTip />;
});

export const SoundbiteSentenceToolbar: React.FC<{
  model: SoundbiteModel;
  sentenceId: SentenceId;
}> = observer(({ model, sentenceId }) => {
  const compactMode =
    AppFactory.root.userSettings.compactSentenceToolbarEnabled;
  return (
    <ToolbarStyles>
      <div className="toolbar-inner">
        <SoundbiteOnlyButtons model={model} sentenceId={sentenceId} />
        <span className="spacer" />
        <ReplayButton model={model} />
        {compactMode ? (
          <>
            <SnailButton model={model} />
            <CopyButton model={model} />
          </>
        ) : null}
        <OverflowButton model={model} sentenceId={sentenceId} />
      </div>
    </ToolbarStyles>
  );
});
