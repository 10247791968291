import * as React from 'react';

import { ReplayIcon } from '@naan/icons/replay-icon';
import { SnailIcon } from '@naan/icons/snail-icon';
import { CopyIcon } from '@naan/icons/copy-icon';
import { copyToClipboard } from '@common/hooks/use-copy-powers';
import { NotificationService } from '@app/notification-service';
import { EyeIcon } from '@naan/icons/eye-icon';
import {
  NotationIcon,
  TranslationIcon,
} from 'player/views/elements-presentations/sentence-toolbar/sentence-toolbar-icons';
import { isStudyModel } from 'player/models/player-model-handle';
import { MenuActionItem } from './menu-action-item';
import { MenuContext } from './sentence-menu-context';

import __ from '@core/lib/localization';

export const CopyActionItem = () => {
  // (window as any).model = model;

  const { model, onDismiss } = React.useContext(MenuContext)!;

  const handleCopy = React.useCallback(() => {
    copyToClipboard(model.currentSentenceText)
      .then(
        () => {
          NotificationService.open({
            type: 'success',
            message: __('Copied to clipboard', 'copiedToClipboard'),
          });
        },
        () => {
          NotificationService.open({
            type: 'error',
            message: __(
              'Failed to copy to clipboard',
              'failedToCopyToClipboard'
            ),
          });
        }
      )
      .finally(() => {
        onDismiss();
      });
  }, [model, onDismiss]);

  return (
    <MenuActionItem
      action={() => handleCopy()}
      icon={<CopyIcon />}
      label={__('Copy', 'copy')}
      shortcut={__('C', 'keyboard.c')}
    />
  );
};

export const ReplayActionItem = () => {
  const { model, onDismiss } = React.useContext(MenuContext)!;

  return (
    <MenuActionItem
      action={() => {
        onDismiss();
        model.replayCurrentSentence();
      }}
      icon={<ReplayIcon />}
      label={__('Replay', 'replay')}
      shortcut={__('Enter', 'keyboard.enter')}
    />
  );
};

export const SlowReplayActionItem = () => {
  const { model, onDismiss } = React.useContext(MenuContext)!;

  return (
    <MenuActionItem
      action={() => {
        onDismiss();
        model.snailReplayCurrentSentence();
      }}
      icon={<SnailIcon />}
      label={__('Slow replay', 'slowReplay')}
      shortcut={__('Shift+Enter', 'keyboard.shiftEnter')}
    />
  );
};

export const StudyFromHereActionItem = () => {
  const { model, onDismiss } = React.useContext(MenuContext)!;

  return (
    <MenuActionItem
      action={() => {
        onDismiss();
        model.togglePlayerMode();
      }}
      icon={<EyeIcon />}
      label={__('Study from here', 'studyFromHere')}
    />
  );
};

export const TranslationActionItem = () => {
  const { model, onDismiss, sentenceId } = React.useContext(MenuContext)!;

  const active = model.shouldDisplaySentenceInlineTranslation(sentenceId);

  return (
    <MenuActionItem
      action={() => {
        onDismiss();
        model.toggleCurrentSentenceInlineTranslation();
      }}
      icon={<TranslationIcon />}
      label={
        active
          ? __('Hide translation', 'hideTranslation')
          : __('Show translation', 'showTranslation')
      }
      shortcut={__('Tab', 'keyboard.tab')}
    />
  );
};

export const NotationActionItem = () => {
  const { model, onDismiss, sentenceId } = React.useContext(MenuContext)!;

  // to keep ts happy
  if (!isStudyModel(model)) {
    return null;
  }
  const notationCount = isStudyModel(model)
    ? model.getNotationCountForSentence(sentenceId)
    : 0;
  const active = model.displayNotationsInlineSentenceId === sentenceId;
  const hasNotations = notationCount > 0;
  const notationLabel = String(notationCount);

  return (
    <MenuActionItem
      action={() => {
        onDismiss();
        model.toggleCurrentSentenceInlineNotations();
      }}
      disabled={!hasNotations}
      icon={<NotationIcon text={notationLabel} />}
      label={
        active ? __('Hide vocab', 'hideVocab') : __('Show vocab', 'showVocab')
      }
      shortcut={__('V', 'keyboard.v')}
    />
  );
};
