// experimental state to drive profile layout back navigation
// shouldn't need to be reactive, so just using a global object

// import { AppFactory } from '@app/app-factory';
// import { bugsnagNotify } from '@app/notification-service';
import Cookies from 'js-cookie';
import { LocaleCode } from '@utils/util-types';
// import { appConfig } from 'app/env';

const SELECTED_L2_COOKIE_KEY = 'selectedL2';
export const LONG_COOKIE_AGE_DAYS = 400; // beware, duplicated from user-manager

// reset by the /teach and /learn layouts
export const ReturnNavState = {
  appMode: 'learn' as 'learn' | 'teach',
  selectedL2: '' as LocaleCode,
  // l2: 'es',
  // l2 will be locked once designated for an app session
  // selectedL2: undefined as 'es' | 'en' | undefined,
  storyListVisited: false, // if never visited, then we can't safely pop back from story detail view and instead navigate explicitly to the dashboard
  storyDetailVisited: false, // if neither list or detail visited, then we can't safely pop back from study view and similarly nav to dashboard
  calendarVisited: false,

  // @aramando, thoughts about nested these here or top level exports?
  canPopFromStoryDetail: () => {
    return ReturnNavState.storyListVisited;
  },

  canPopFromStudy: () => {
    return ReturnNavState.storyListVisited || ReturnNavState.storyDetailVisited;
  },

  canPopFromSubscribe: () => {
    // todo: give this more thought
    return ReturnNavState.storyListVisited || ReturnNavState.storyDetailVisited;
  },

  // todo: should probably refactor this into a single class instance at this point.
  reset: (l2: string) => {
    ReturnNavState.appMode = 'learn';
    // ReturnNavState.l2 = l2;
    // ReturnNavState.selectedL2 = undefined;
    ReturnNavState.storyListVisited = false;
    ReturnNavState.storyDetailVisited = false;
  },

  // todo: should probably lock the l2 for the entire spa session
  setL2: (l2: LocaleCode) => {
    if (l2 !== ReturnNavState.selectedL2) {
      ReturnNavState.selectedL2 = l2; // || 'es';
      ReturnNavState.setL2Cookie(l2);
    }
    // if (ReturnNavState.l2 !== AppFactory.root.l2) {
    //   AppFactory.root.selectL2(ReturnNavState.l2).catch(bugsnagNotify);
    // }
  },

  get l2(): LocaleCode {
    // return AppFactory.root.l2;
    return ReturnNavState.selectedL2;
  },

  getL2Cookie(): LocaleCode {
    // if (appConfig.forcedL2) {
    //   return appConfig.forcedL2;
    // }

    const raw = Cookies.get(SELECTED_L2_COOKIE_KEY);
    if (raw) {
      return raw as LocaleCode;
    } else {
      return undefined;
    }
  },

  setL2Cookie(l2: LocaleCode) {
    Cookies.set(SELECTED_L2_COOKIE_KEY, l2, {
      expires: LONG_COOKIE_AGE_DAYS,
    });
  },

  clearL2Cookie() {
    Cookies.remove(SELECTED_L2_COOKIE_KEY);
  },

  deepLinkBaseL2Path() {
    return '/' + (ReturnNavState.getL2Cookie() || 'es');
  },
};

export const classroomPortalMode = () => {
  return ReturnNavState.appMode === 'teach';
};

(window as any).returnNavState = ReturnNavState;
