import * as React from 'react';
import classNames from 'classnames';
import { ReplayIcon } from '@naan/icons/replay-icon';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { StudyModel } from 'study/models/study-model';
// import { scrollToCurrentLine } from 'study/views/fx/scrolling';
import { presentSentenceActions } from 'study/views/sentence-modal';
import { EyeIcon } from '@naan/icons/eye-icon';
import { SoundbiteModel } from 'soundbite/models/soundbite-model';
import { NotationIcon, TranslationIcon } from './sentence-toolbar-icons';
import { paletteKey } from 'study/views/study-palette';
import { styled } from '@naan/stitches.config';
import { SentenceId } from '@tikka/basic-types';
import { ControlledHotspot } from 'components/ui/onboarding/hotspot';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { SnailIcon } from '@naan/icons/snail-icon';
import { CopyIcon } from '@naan/icons/copy-icon';
import { copyToClipboard } from '@common/hooks/use-copy-powers';
import { NotificationService } from '@app/notification-service';

import __ from '@core/lib/localization';

type StudyOrSoundbiteModel = StudyModel | SoundbiteModel;

const TBButtonWrapper = styled('button', {
  all: 'unset',
  cursor: 'default',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 4,
  padding: '10px 8px',
  $$_color: paletteKey('$$sentence_toolbar__color'),
  color: '$$_color',
  // background: '$red-100 ',

  '& .icon': {
    // color: '$colors$gray-800',
    '& svg': {
      width: 28,
      height: 28,
    },
  },

  '&:active': {
    '& .icon': {
      opacity: 0.8,
      // '& svg': {
      //   filter: 'drop-shadow(0px 0px 1px rgb(0 0 0 / 0.4))',
      // },
    },
  },

  '& .label': {
    textStyle: 'small-text-bold',
  },

  '&.translation-button': {
    '&.active': {
      $$_color: '$colors$teal-500',
    },
    '&.disabled': {
      $$_color: '$colors$gray-200',
    },
  },

  '&.notation-button': {
    '&.active': {
      $$_color: '$colors$green-500',
    },
    '&.disabled': {
      $$_color: '$colors$gray-200',
    },
  },
});

type TBButtonWrapperProps = React.ComponentProps<typeof TBButtonWrapper>;

const TBButton: React.FC<
  TBButtonWrapperProps & {
    icon: React.ReactNode;
    label?: string;
  }
> = observer(({ children, icon, label, ...props }) => {
  const compactMode =
    AppFactory.root.userManager.userData.userSettings
      .compactSentenceToolbarEnabled;
  const showLabel = label && compactMode === false;
  return (
    <TBButtonWrapper {...props}>
      <span className="icon">{icon}</span>
      {showLabel ? <span className="label">{label}</span> : null}
    </TBButtonWrapper>
  );
});

export const StudyFromHereButton: React.FC<{
  model: StudyModel;
}> = ({ model }) => {
  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      // scrollToCurrentLine();
      model.togglePlayerMode();
    },
    [model]
  );

  return (
    <TBButton
      onClick={handleClick}
      icon={<EyeIcon />}
      label={__('Study from here', 'studyFromHere')}
    />
  );
};

export const TranslationButton: React.FC<{
  disabled?: boolean;
  active: boolean;
  action: () => void;
}> = observer(({ active, action, disabled = false }) => {
  const l1 = AppFactory.root.l1.toUpperCase();
  const handleTranslationClick = React.useCallback(
    (dismissCallback: () => void) => (e: React.MouseEvent) => {
      dismissCallback();
      e.preventDefault();
      e.stopPropagation();
      // scrollToCurrentLine();
      action();
    },
    [action]
  );

  return (
    <ControlledHotspot
      keyName="hotspot:inlineTranslation"
      disabled={disabled}
      renderContent={dismissCallback => (
        <TBButton
          icon={<TranslationIcon />}
          label={l1}
          className={classNames('translation-button', {
            active: active,
            disabled: disabled,
          })}
          onClick={handleTranslationClick(dismissCallback)}
        />
      )}
    />
  );
});

export const NotationsButton = ({
  notationCount,
  active,
  action,
}: {
  active: boolean;
  action: () => void;
  notationCount: number;
}) => {
  const hasNotations = notationCount > 0;
  const notationLabel = String(notationCount);

  const handleNotationClick = React.useCallback(
    (dismissCallback: () => void) => (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      dismissCallback();
      if (hasNotations) {
        action();
      }
    },
    [hasNotations, action]
  );

  return (
    <ControlledHotspot
      keyName="hotspot:inlineNotation"
      disabled={hasNotations === false}
      renderContent={dismissCallback => (
        <TBButton
          icon={<NotationIcon text={notationLabel} />}
          label={__('Vocab', 'vocab')}
          className={classNames('notation-button', {
            active,
            disabled: !hasNotations,
          })}
          onClick={handleNotationClick(dismissCallback)}
        />
      )}
    />
  );
};

export const ReplayButton = ({ model }: { model: StudyOrSoundbiteModel }) => {
  const handleReplayClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      model.replayCurrentSentence();
    },
    [model]
  );

  return (
    <TBButton
      onClick={handleReplayClick}
      icon={<ReplayIcon />}
      label={__('Replay', 'replay')}
    />
  );
};

export const SnailButton = ({ model }: { model: StudyOrSoundbiteModel }) => {
  const handleReplayClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      model.snailReplayCurrentSentence();
    },
    [model]
  );

  return (
    <TBButton
      onClick={handleReplayClick}
      icon={<SnailIcon />}
      // label={__('Replay', 'replay')}
    />
  );
};

export const CopyButton = ({ model }: { model: StudyOrSoundbiteModel }) => {
  // this is duplicated with the copy menu action, but refactoring was more work than it was worth
  const handleCopy = React.useCallback(() => {
    copyToClipboard(model.currentSentenceText).then(
      () => {
        NotificationService.open({
          type: 'success',
          message: __('Copied to clipboard', 'copiedToClipboard'),
        });
      },
      () => {
        NotificationService.open({
          type: 'error',
          message: __('Failed to copy to clipboard', 'failedToCopyToClipboard'),
        });
      }
    );
  }, [model]);

  return (
    <TBButton
      onClick={handleCopy}
      icon={<CopyIcon />}
      // label={__('Replay', 'replay')}
    />
  );
};

export const OverflowButton: React.FC<{
  model: StudyOrSoundbiteModel;
  sentenceId: SentenceId;
}> = ({ model, sentenceId }) => {
  const handleOverflowClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      presentSentenceActions(model, sentenceId);
    },
    [model, sentenceId]
  );

  return <TBButton onClick={handleOverflowClick} icon={<OverflowIcon />} />;
};
