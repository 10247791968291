import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { styled } from '@naan/stitches.config';
import { appConfig } from '@app/config';
import { formatDate } from '@utils/format-date';
import { Image } from '@naan/primitives/image';
import { daysCount } from '@core/lib/localization-helpers';
import { PlainMarkdown } from '@naan/primitives/text';

import illustrationSrc from './assets/piggybank-illustration.png';

import __ from 'core/lib/localization';

const Wrapper = styled('div', {
  background: '$yellow-300',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 16,
  borderRadius: 16,
  gap: 8,
  '& .image': {
    img: {
      width: 80,
      aspectRatio: 1,
    },
  },
  '& .copy': {
    color: '$colors$black-alpha-80',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    '& h3': {
      textAlign: 'center',
      textStyle: 'small-heading',
    },
  },

  '@medium': {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gap: 16,
    '& .image': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .copy h3': {
      textAlign: 'left',
    },
  },
});

export const PriceIncreaseSubscribeCta = observer(
  ({ flavor }: { flavor: 'trial' | 'fullAccess' }) => {
    const { userManager, storyManager } = AppFactory.root;
    const { membership } = userManager;
    const showPrinceIncreaseMessage = membership.showPriceIncreaseInlineNotice;

    if (!showPrinceIncreaseMessage) {
      return null;
    }

    const date = formatDate(appConfig.priceIncreaseDate, 'medium');
    const productName = storyManager.productName;
    const remainingDays = membership.remainingFullAccessInDays;

    const lines = [
      `${productName} costs the same as three years ago, but on **${date}** prices are going up.`,
    ];

    if (flavor === 'fullAccess') {
      const days = daysCount(remainingDays);
      lines.push(
        'Extend your membership with a subscription and enjoy 2021 prices for as long as you stay with us.'
      );
      lines.push(
        `You won't be charged until your current access expires in ${days}, and you can cancel anytime.`
      );
    } else {
      lines.push(
        "Start your journey to Spanish fluency with us now and you'll enjoy 2021 prices while you remain subscribed."
      );
    }

    return (
      <Wrapper>
        <div className="image">
          <Image src={illustrationSrc} />
        </div>
        <div className="copy">
          <h3>{__('Beat the price increase', 'beatThePriceIncrases')}</h3>
          <div className="text-body">
            <PlainMarkdown source={lines.join(' ')}></PlainMarkdown>
          </div>
        </div>
      </Wrapper>
    );
  }
);
