import ReactGA from 'react-ga4';

import { appConfig } from 'app/env';
import { createLogger } from 'app/logger';
import { AnalyticsAdapter } from '../analytics-adapter';
import { notEmpty } from '@utils/conditionals';

const log = createLogger('analytics:ga');

const gaKey = appConfig.analytics.googleAnalyticsKey;
const prefix = appConfig.analytics.eventPrefix;

export function retrieveGTag(): (...args: any[]) => void {
  return (
    (window as any).gtag ??
    function (): void {
      log.warn('gtag not found');
    }
  );
}

export class GoogleAnalytics extends AnalyticsAdapter {
  serviceName = 'GoogleAnalytics';
  isEnabled = notEmpty(gaKey);

  // userId: string;
  // clientId: string;
  // private initialized: boolean = false;

  constructor() {
    super();
    if (this.isEnabled) {
      ReactGA.initialize(gaKey);
      log.debug('enabled - initialized');
    } else {
      log.debug('disabled');
    }
  }

  identify(userId: string /*, data: any*/) {
    log.debug(`identify w/ userId: ${userId}`);
    // this.userId = userId;
    // this.clientId = clientId;
    // ReactGA.initialize(gaKey, {
    //   gaOptions: { userId, clientId: clientId },
    // });
    // this.initialized = true;
    // if (this.initialized) {
    ReactGA.set({ userId }); // todo: revisit this
    // } else {
    //   this.ensureInitialized();
    // }
  }

  aliasNewAccount(userId: string) {
    log.info(`alias - ignored`);
  }

  // setProfileData(data: object) {
  //   log.info(`setProfile: ${JSON.stringify(data)}`);
  //   ReactGA.set(data); // todo: revisit this
  // }

  reset() {
    log.info('reset');
    ReactGA.set({ userId: null }); // todo: revisit this
  }

  // identifyAnonymous(clientId: /*installationId*/ string) {
  //   log.debug('GA.identify w/ installationId', { clientId });
  //   this.userId = null;
  //   this.clientId = clientId;
  //   // ReactGA.initialize(gaKey, { gaOptions: { clientId } });
  //   // this.initialized = true;
  //   if (this.initialized) {
  //     ReactGA.set({ userId: null, clientId });
  //   } else {
  //     this.ensureInitialized();
  //   }
  // }

  // ensureInitialized() {
  //   if (!this.initialized) {
  //     ReactGA.initialize(gaKey, {
  //       gaOptions: { userId: this.userId, clientId: this.clientId },
  //     });
  //     this.initialized = true;
  //   }
  // }

  track(eventName: string, data?: any) {
    log.debug(`track: ${eventName}`);
    // this.ensureInitialized();
    const params = { category: prefix } as any;
    if (data) {
      for (const prop in data) {
        params[`metric_${prop}`] = data[prop];
      }
    }
    // log.debug(`params: ${JSON.stringify(params)}`);
    ReactGA.event(eventName, params);
  }

  page(pageName: string, data?: any) {
    // @armando, do you recall why we didn't track page view if not initalized, but we still tracked events?
    // if (this.initialized) {
    log.debug(`page: ${pageName}`); // todo support data params?
    // this.ensureInitialized();
    ReactGA.send({
      hitType: 'pageview',
      page: pageName,
      title: pageName,
      // metric_foo: 'bar', // not sure if custom metric data can be added to page views
    });
    // }
  }
}
