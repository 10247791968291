import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { observer } from 'mobx-react';
import { ChapterListHeader } from './chapter-item-contents/chapter-list-header';
import { Box } from '@naan/primitives/box/box';
import { ChapterItemContents } from './chapter-item-contents/chapter-item-contents';
import { ChapterSoundbites } from './chapter-item-contents/chapter-soundbites';
import { useNavigateToStudy } from 'lib/hooks/use-navigate-to-study';
import { PlayerMode } from '@common/misc-types';
import { ReviewChapterIcon } from './components/review-chapter-icon';
import { ChapterReviewCard } from './chapter-item-contents/chapter-review-card';
import { ChapterReviewOverflowMenu } from './chapter-item-contents/chapter-review-overflow-menu';

import __ from '@core/lib/localization';

// special review mode
export const CurrentNotFurthest = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const navigateToStudy = useNavigateToStudy();

    const midListen = chapter.currentMillis > 0; // && isPlayerAction;
    const label = midListen
      ? __('Continue relisten', 'continueRelisten')
      : __('Relisten', 'relisten');

    const onButtonClick = React.useCallback(() => {
      navigateToStudy(
        chapter,
        PlayerMode.FLUENT_LISTEN,
        chapter.currentMillis,
        false /* viaDetail */
      );
    }, [chapter, navigateToStudy]);

    return (
      <Box style={{ overflowY: 'clip' }}>
        <ChapterListHeader
          chapter={chapter}
          presentation="current"
          stationIcon={<ReviewChapterIcon />}
        />
        <ChapterItemContents>
          <ChapterSoundbites chapter={chapter} forceOpen />
          <ChapterReviewCard
            overflowMenu={
              <ChapterReviewOverflowMenu chapter={chapter} inReviewMode />
            }
            buttonLabel={label}
            onClick={onButtonClick}
            progress={midListen ? chapter.currentPercentage : undefined}
          />
        </ChapterItemContents>
      </Box>
    );
  }
);
