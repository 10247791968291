import * as React from 'react';
// import { observer } from 'mobx-react';
import { namedObserver } from 'lib/named-observer';
import { AppFactory } from '@app/app-factory';
import { Story } from '@core/models/story-manager/story';
import { ChapterRowUiProvider } from 'story/chapter-list-item/chapter-item-contents/chapter-row-ui-model';
import { ChapterActionButton } from 'story/chapter-list-item/chapter-item-contents/chapter-action-button';
import { track } from '@app/track';
import { SteppedInterstitialLayout } from './component/stepped-interstitial-layout';

import __ from '@core/lib/localization';

export const ListenInterstitial = namedObserver(
  'ListenInterstitial',
  ({ story }: { story: Story }) => {
    // const navigateToStudy = useNavigateToStudy();

    // const handleContinue = () => {
    //   const chapter = story.firstChapter;
    //   const { currentMillis: ms /*, midListen TODO*/ } = chapter;
    //   const viaDetail = false;
    //   navigateToStudy(chapter, PlayerMode.FLUENT_LISTEN, ms, viaDetail);
    // };
    const chapter = story.firstChapter;

    const { l2Localized } = AppFactory.root;

    React.useEffect(() => {
      track('onboarding__interstitial_shown', { stage: 'listen' });
    }, []);

    return (
      <SteppedInterstitialLayout
        story={story}
        stage={2}
        title={__('Natural Listen', 'naturalListen')}
        copy={__(
          'Now listen again at normal speed so your ears learn the sound of real spoken {{l2Localized}}.',
          'listenInterstitialCopy',
          { l2Localized }
          // "The last step is listening through again, at natural speed. This bakes in the sound of the Spanish as you'll encounter it in the real world.",
          // 'theLastStepIsListeningThroughAgainAtNaturalSpeed'
        )}
        renderButton={() => (
          <ChapterRowUiProvider chapter={chapter}>
            <ChapterActionButton
              chapter={chapter}
              viaDetail={false}
              presentation="teal"
            />
          </ChapterRowUiProvider>
        )}
      />
    );
  }
);
