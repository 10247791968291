import * as React from 'react';
import * as Dialog from '@naan/primitives/modals';
import { Channel } from '@core/models/story-manager/channel';
import { createLogger } from '@common/log';
import { useNavigate } from 'react-router-dom';
import { storiesTagFiltered } from 'components/nav/path-helpers';
import { styled } from '@naan/stitches.config';
import { PlainMarkdown } from '@naan/primitives/text';

import __ from '@core/lib/localization';

import mothImage from 'components/learn/dashboard/assets/channel-thumb-the-moth.png';
import truthImage from 'components/learn/dashboard/assets/channel-thumb-the-truth.png';
import radiolabImage from 'components/learn/dashboard/assets/channel-thumb-radiolab.png';
import { ChannelAvatar } from './channel-avatar';
import { AppFactory } from '@app/app-factory';
import { ExternalLink } from '@naan/primitives/text/external-link';
import { GlobeIcon } from '@naan/icons/globe-icon';

const log = createLogger('channel-badge');

const brandImageMap = {
  moth: mothImage,
  truth: truthImage,
  radiolab: radiolabImage,
};

const getChannelImage = (channel: Channel): string | null => {
  return brandImageMap[channel.slug as keyof typeof brandImageMap] ?? null;
};

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 0',
  gap: 16,
  '& > h2': {
    textStyle: 'small-heading',
  },
  [`& ${ChannelAvatar}`]: {
    marginBottom: 8,
  },
  '& .external-link': {
    '& > a': {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      color: '$textSecondary',
      textDecoration: 'none',
      '& .icon': {
        '& svg': {
          width: 20,
          height: 20,
        },
      },
    },
  },
});

export const StoryChannelModal = ({
  channel,
  onDismiss,
  showShowAllButton = true,
}: {
  channel: Channel;
  onDismiss: () => void;
  showShowAllButton?: boolean;
}) => {
  const navigate = useNavigate();
  const onShowAll = React.useCallback(() => {
    onDismiss();
    navigate(storiesTagFiltered('channel', channel.title));
  }, [channel.title, navigate, onDismiss]);

  const imageSrc = getChannelImage(channel);

  if (!imageSrc) {
    log.info(`image for channel ${channel.slug} not found`);
    return null;
  }

  return (
    <Dialog.Container open onDismiss={onDismiss}>
      <Dialog.Body>
        <Wrapper>
          <ChannelAvatar channel={channel} size="medium" />
          <h2>{channel.tagline}</h2>
          <PlainMarkdown source={channel.description} />
          <div className="external-link">
            <ExternalLink href={channel.weblinkUrl}>
              <span className="icon">
                <GlobeIcon />
              </span>
              {channel.weblink}
            </ExternalLink>
          </div>
        </Wrapper>
      </Dialog.Body>
      <Dialog.ButtonsContainer direction="column">
        {showShowAllButton ? (
          <Dialog.Button
            onClick={onShowAll}
            presentation="teal"
            label={__('Show all stories', 'showAllStories')}
          />
        ) : null}
        <Dialog.Button
          onClick={onDismiss}
          presentation="grayLight"
          label={__('Close', 'close')}
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};

export const presentStoryChannelModal = (channel: Channel) => {
  AppFactory.dialogPresenter.present(onDismiss => (
    <StoryChannelModal
      channel={channel}
      onDismiss={onDismiss}
      showShowAllButton={false}
    />
  ));
};
