import {
  Notation,
  ElementList,
  IDTOf,
  EmptyElementList,
  Chapter,
  ChapterNote,
  Sentence,
} from '@tikka/client/client-aliases';

import { ElementId, WordId } from '@tikka/basic-types';
import {
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
  untracked,
} from 'mobx';
import { StudyData } from '@tikka/client/catalog-types';
import { makeAdhocRangeElement } from '@tikka/elements/ad-hoc-word-range';
import { ChapterCatalogData } from 'core/models/catalog';
import { /*FluentListenStatus,*/ PlayerMode } from 'common/misc-types';
import {
  CreateMembershipList,
  MembershipList,
} from '@tikka/membership-reconciliation/membership-reconciler';
import { CreateElementList } from '@tikka/elements/element-list';
import { createLogger } from 'app/logger';
import {
  BasePlayerModel,
  PLAYER_SESSION_BASE_KEY,
  PlayerSessionData,
  PlayerType,
  TranslationButtonState,
} from 'player/models/base-player-model';
import { LoadingStatus } from 'player/models/player-model';
import { Speaker } from '@core/models/catalog/speaker';
import { AppFactory } from 'app/app-factory';
import { RedactionMode } from 'player/models/redaction-modes';
import { ChapterRef } from '@core/models/user-manager/location-pointer';
import { stripTrailingPunctuation } from '@tikka/misc/string-utils';
import { bugsnagNotify } from '@app/notification-service';
import { OnboardingService } from '@app/onboarding/onboarding-service';
// import { GenericError } from '@core/lib/errors';
// import { track } from '@app/track';

const log = createLogger('study-model');

export class StudyModel extends BasePlayerModel {
  playerType = PlayerType.STUDY;

  chapter: ChapterCatalogData;
  sessionCounter = 0; // hold state needed for updateProgress operations
  firstListen = false; // drives visibility of "Resume natural listen" button

  // @armando, tentatively adding these also a model properties in case that's eaasier to consume
  // within the UI than mapped element types
  chapterTitle: /*Client*/ Chapter = null;
  chapterNotes: /*Client*/ ChapterNote[] = [];

  notations: ElementList<Notation> = EmptyElementList;
  @observable.ref selectedNotationId: IDTOf<Notation> = null;

  startingMode: PlayerMode; // controls mode toggle visibility and per mode completion tracking

  // @observable.ref showingInlineTranslation = false;
  // @observable.ref showingInlineNotations = false;
  @observable.ref displayNotationsInlineSentenceId: ElementId = null;
  @observable.ref displayTranslationInlineSentenceId: ElementId = null;

  // not needed by current player
  // was implemented before scope of initial web player was reduced
  // will perhaps be resurrected in the future

  // @observable.ref listeningFromStart = false;
  // @observable.ref completedListeningFromStart = false;
  // @observable.ref firstListenComplete = false; // true if END_OF_CHAPTER has ever been reached

  constructor() {
    super();
    makeObservable(this);
  }

  // this flavor only used by debugReset, should be better named/factored
  async initFromData(data: StudyData) {
    log.debug('initFromData');
    await this.initFromPlayerData(data);
    this.setReady();
    log.debug('initFromData complete');
  }

  get studyData(): StudyData {
    return this.data as StudyData;
  }

  get chapterRef(): ChapterRef {
    return {
      unit: this.studyData.unitNumber,
      chapter: this.studyData.position,
    };
  }

  get dataSourceUrl(): string {
    return this.chapter.playerDataUrl;
  }

  set dataSourceUrl(value) {
    // no-op, needed to appease unit tests for some reason
  }

  async initFromStudyData(data: StudyData) {
    await this.initFromPlayerData(data);

    const chapters = this.elements.filterByKind('CHAPTER');
    if (chapters.notEmpty()) {
      const chapterTitle = chapters.values[0];
      const chapterNotes = this.elements.filterByKind('CHAPTER_NOTE').values;
      this.chapterNotes = chapterNotes;
      // for the moment stuffing the notes both into the chapter title element and directly on the study model.
      // tighten this up once UI refactored
      if (chapterTitle) {
        chapterTitle.notes = chapterNotes;
      } else {
        bugsnagNotify(
          `unexpectedly missing chapterTitle - slug: ${data?.slug}`
        );
      }
      this.chapterTitle = chapterTitle;
    }

    this.notations = this.elements.filterByKind('NOTATION');

    // // more state to reset?
    // this.listeningFromStart = false;
    // this.completedListeningFromStart = false;
    // this.firstListenComplete = false;

    // needed to manage notation panel open/minimized state
    reaction(
      () => this.transportState.pauseAfterTriggeredCount,
      () => this.handlePauseAfterTrigger()
    );
  }

  handlePauseAfterTrigger() {
    // will but the notation panel into the 'opened' state as long as this
    // sentence stays current
    this.notationFocusedSentenceId = this.currentSentenceId;
  }

  resolveEndOfMaterialMillis(): number {
    const chapterCompletes = this.elements.filterByKind('CHAPTER_COMPLETE');
    if (chapterCompletes.values.length > 0) {
      const chapterComplete = chapterCompletes.values[0];
      const endOfChapterWordAddress = chapterComplete.address;
      const result = this.words.timeIntervals.intervalAt(
        endOfChapterWordAddress - 1
      ).end;
      return result;
    }
    // defaults to last sentence end time
    return super.resolveEndOfMaterialMillis();
  }

  async resetSession() {
    this.setStatus(LoadingStatus.UNINITIALIZED);
    return this.initFromData(this.studyData);
  }

  @computed
  get selectedNotationMembershipList() {
    const notation = this.selectedNotationElement;
    let elements = [];
    const words = this.elements.words;
    if (notation) {
      const begin = notation.address.toString() as WordId;
      const end = notation.endAddress.toString() as WordId;
      elements.push(makeAdhocRangeElement({ begin, end }, words));
    }
    return CreateMembershipList({
      memberships: ['SELECTED_NOTATION'],
      elements: CreateElementList({ elements, words }),
      useRanges: true,
    });
  }

  get wordMembershipLists(): Map<string, MembershipList> {
    const result = super.wordMembershipLists;
    result.set('selectedNotationWord', this.selectedNotationMembershipList);
    return result;
  }

  async initChapterSession({
    chapter,
    playerMode,
    startMillis,
    sessionCounter,
  }: {
    chapter: ChapterCatalogData;
    playerMode: PlayerMode;
    startMillis: number;
    sessionCounter: number; // listening pointer iteration. needed to update progress operations can be idempotent
  }) {
    this.chapter = chapter;
    this.playerMode = playerMode ?? PlayerMode.STUDY;
    this.startingMode = this.playerMode;
    this.pendingSeekMillis = startMillis;
    this.sessionCounter = sessionCounter;
    this.firstListen = !chapter.isFirstListenComplete;

    const slug = chapter.storySlug;
    const metadataLoaded = await this.awaitPlayerAudioMetadata({
      reportingSlug: slug /*, startMillis*/,
    });
    if (metadataLoaded && startMillis) {
      // let audioLoaded = false;

      // experimentally attempt to kick ios 17.4 to trigger 'canplaythrough' event
      const playPaused =
        await this.player.audioTransport.audioElement.awaitPlayPause();

      // this didn't help with ios 17.4 issue
      // this.player.audioTransport.seekWhenAble({ timeMillis: startMillis });

      if (
        playPaused /* todo: consider forcing attempt to wait if not a blob: url */
      ) {
        /*audioLoaded =*/ await this.awaitCanPlayThrough();
      } else {
        // if pause/play error out. presume that we're on a device that can't handle seeking into blob audio
        // if (this.isBlobAudioUrl) {
        //   log.warn('playPause failed - disabling audio cache');
        //   track('settings__auto_download_force_disabled');
        //   AppFactory.root.userManager.userData.userSettings.disableAutoDownload();
        //   throw new GenericError('playPause failed', {
        //     userMessage:
        //       'Downloaded audio error. Your settings have been updated. Please try again',
        //   });
        // } else {
        //   const message = `playPause failed with non-blob audio: ${this._audioUrl}`;
        //   log.error(message);
        //   bugsnagNotify(message);
        //   // seek will be bypassed and audio will play from the start
        // }
      }
      // if (audioLoaded) {
      //   this.player.seek(startMillis);
      // } // else busted experience. will play from the start after having waiting 30 seconds
    }

    if (playerMode === PlayerMode.FLUENT_LISTEN) {
      OnboardingService.instance.onNaturalListenLaunch();
    }
  }

  get fluentListenMode(): boolean {
    return this.playerMode === PlayerMode.FLUENT_LISTEN;
  }

  get studyMode(): boolean {
    return this.playerMode === PlayerMode.STUDY;
  }

  get enableModeToggle(): boolean {
    return this.startingMode === PlayerMode.FLUENT_LISTEN;
  }

  get redactionMode() {
    if (this.fluentListenMode) {
      return RedactionMode.SHOW_NONE;
    }
    // return this._redactionMode;
    return super.redactionMode;
  }

  getSentenceRedactionMode(sentenceId: ElementId) {
    if (this.fluentListenMode) {
      // todo: should be able to simplify the redaction state handling now that we override here
      return RedactionMode.SHOW_NONE;
    } else {
      return super.getSentenceRedactionMode(sentenceId);
    }
  }

  togglePlayerMode() {
    runInAction(() => {
      // needed so that collapse button still works after toggling to fluent-listen mode and back
      if (this.notationPanelExpanded) {
        this.collapseNotationPanel();
      }
      const { playerSettings } = AppFactory.root.userManager.userData;
      if (this.playerMode === PlayerMode.STUDY) {
        // playerSettings.setAll({
        //   playbackRate: this.player.playbackRate,
        //   redactionMode: this.redactionMode, // not strictly needed now since shadowed, but might as well
        //   // won't be fully persisted until chapter complete or player cleanly exited
        // });
        playerSettings.setPlaybackRate(this.player.playbackRate);
        this.playerMode = PlayerMode.FLUENT_LISTEN;
        // this.setTranslationsShown(false);
        this.player.setNormalPlaybackRate();
      } else if (this.playerMode === PlayerMode.FLUENT_LISTEN) {
        this.playerMode = PlayerMode.STUDY;
        // this.listeningFromStart = false;
        this.player.setPlaybackRate(playerSettings.playbackRate);
      }
    });
  }

  // only show if in fluent listen mode and there's a current sentence (i.e. not at very start or very end)
  get showStudyFromHere(): boolean {
    return this.fluentListenMode && !!this.currentSentenceId;
  }

  get showListenFromHere(): boolean {
    return (
      this.studyMode &&
      // this.playerStatus === PlayerStatus.PLAYING &&
      this.startingMode === PlayerMode.FLUENT_LISTEN
      // !this.firstListen
    );
  }

  get translationsShown() {
    if (this.fluentListenMode) {
      return false;
    }
    return this._translationsShown;
  }

  get expandNotationsButtonShown(): boolean {
    return this.replayButtonsShown && !this.notationPanelExpanded;
  }

  get replayButtonsShown(): boolean {
    return this.studyMode && !this.isPlaying && !!this.currentSentenceId;
  }

  replayCurrentSentence() {
    // needed so that collapse button still works after replay
    this.collapseNotationPanel();
    super.replayCurrentSentence();
  }

  snailReplayCurrentSentence() {
    this.collapseNotationPanel();
    super.snailReplayCurrentSentence();
  }

  // beware! lazily evaluation side-effect
  @computed
  get notationPanelExpanded(): boolean {
    if (!this.notationFocusedSentenceId) {
      return false;
    }

    const result =
      this.notationFocusedSentenceId === this.currentSentenceId &&
      this.hasNotationsContent && // panel always minimized if no vocab to show
      !this.translationsShown; // automatically hide when translation panel opened
    log.debug(`notationPanelExpanded - result: ${String(result)}`);

    if (!result) {
      log.debug(`notationPanelExpanded - resetting focused id`);
      untracked(() => {
        // lazily reset our notaton panel state as a lazy side-effect
        // when the current setentence changes
        this.notationFocusedSentenceId = null;
      });
    }
    return result;
  }

  toggleNotationPanel() {
    log.debug('toggleNotationPanel');
    if (this.notationFocusedSentenceId) {
      // this.notationFocusedSentenceId = null;
      this.collapseNotationPanel();
    } else {
      this.expandNotationPanel();
      // this.notationFocusedSentenceId = this.currentSentenceId;
    }
  }

  expandNotationPanel() {
    if (this.translationsShown) {
      log.debug(
        'expandNotationPanel - automatically closing translation panel'
      );
      this.toggleTranslations();
    }
    this.notationFocusedSentenceId = this.currentSentenceId;
  }

  collapseNotationPanel() {
    this.notationFocusedSentenceId = null;
  }

  @computed
  get showingNotationsPanel() {
    if (this.isPaused && this.studyMode) {
      return true;
    }
    return false;
  }

  @computed
  get notationsPanelContent(): Notation[] {
    if (!this.showingNotationsPanel) {
      return [];
    }
    const sentence = this.currentSentenceElement;
    if (!sentence) {
      return [];
    }
    const notations = this.notations;
    return notations.getElementsIntersectRangeOf(sentence) ?? [];
  }

  getNotationsForSentence(sentenceId: ElementId): Notation[] {
    const sentence = this.elements.getElement(sentenceId);
    if (!sentence) {
      return [];
    }
    const notations = this.notations;
    return notations.getElementsIntersectRangeOf(sentence as Sentence) ?? [];
  }

  getNotationCountForSentence(sentenceId: ElementId): number {
    return this.getNotationsForSentence(sentenceId).length;
  }

  get hasNotationsContent(): boolean {
    // this was incorrectly empty when playing
    // return this.notationsPanelContent.length > 0;

    return this.notationCount > 0;
  }

  get notationCount(): number {
    const sentence = this.currentSentenceElement;
    if (!sentence) {
      return 0;
    }
    const matched = this.notations.getElementsIntersectRangeOf(sentence) ?? [];
    return matched.length;
  }

  selectNotationId(id: IDTOf<Notation>) {
    log.trace(`selectNotation(${id})`);
    this.selectedNotationId = id;
  }

  toggleCurrentSentenceInlineTranslation() {
    const currentSentenceId = this.currentSentenceId;
    if (
      this.displayTranslationInlineSentenceId === currentSentenceId &&
      !this._translationsShown
    ) {
      this.displayTranslationInlineSentenceId = null;
    } else {
      runInAction(() => {
        this.displayTranslationInlineSentenceId = currentSentenceId;
        // TODO jfe consider
        this._translationsShown = false;
      });
    }
  }

  toggleCurrentSentenceInlineNotations() {
    const currentSentenceId = this.currentSentenceId;
    if (this.displayNotationsInlineSentenceId === currentSentenceId) {
      this.displayNotationsInlineSentenceId = null;
    } else {
      this.displayNotationsInlineSentenceId = currentSentenceId;
    }
  }

  closeCurrentSentenceInlineExtras() {
    this.displayTranslationInlineSentenceId = null;
    this.displayNotationsInlineSentenceId = null;
  }

  shouldDisplaySentenceInlineTranslation(sentenceId: ElementId): boolean {
    // TODO factor with below
    if (sentenceId) {
      if (this.translationsShown) {
        return false;
      }
      const id = this.displayTranslationInlineSentenceId;
      if (sentenceId === id) {
        return true;
      } else {
        if (id && id !== this.currentSentenceId) {
          untracked(() => {
            this.displayTranslationInlineSentenceId = null;
          });
        }
      }
    }
    return false;
  }

  shouldDisplaySentenceInlineNotations(sentenceId: ElementId): boolean {
    if (sentenceId) {
      const id = this.displayNotationsInlineSentenceId;
      if (sentenceId === id) {
        return true;
      } else {
        if (id && id !== this.currentSentenceId) {
          untracked(() => {
            this.displayNotationsInlineSentenceId = null;
          });
        }
      }
    }
    return false;
  }

  get hasPreviousNotation(): boolean {
    return !!this.previousNotationId;
  }

  get hasNextNotation(): boolean {
    return !!this.nextNotationId;
  }

  selectNextNotation() {
    if (this.hasNextNotation) {
      this.selectNotationId(this.nextNotationId);
    }
  }

  selectPreviousNotation() {
    if (this.hasPreviousNotation) {
      this.selectNotationId(this.previousNotationId);
    }
  }

  @computed
  get previousNotationId(): IDTOf<Notation> {
    const selected = this.selectedNotationElement;
    if (selected && selected.index > 0) {
      return this.augmentedNotations[selected.index - 1].id;
    } else {
      return null;
    }
  }

  @computed
  get nextNotationId(): IDTOf<Notation> {
    const selected = this.selectedNotationElement;
    if (selected && selected.index < this.augmentedNotations.length - 1) {
      return this.augmentedNotations[selected.index + 1].id;
    } else {
      return null;
    }
  }

  // @computed
  get selectedNotationElement() {
    const id = this.selectedNotationId;
    log.trace(`selectedNotationEl - id: ${this.selectedNotationId}`);
    if (!id) {
      return null;
    }
    const visibleNotations = this.augmentedNotations;
    const returnElement = visibleNotations.find(
      (notation: Notation) => notation.id === id
    );
    if (!returnElement) {
      return null;
    }
    return returnElement;
  }

  // assigns indexes relative to current sentence
  // todo: revisit once sure if needed or not
  get augmentedNotations(): Notation[] {
    const notations = this.notationsPanelContent;
    if (!notations.length) {
      return notations;
    }
    // const words = this.elements.words;
    let index = 0;
    for (const notation of notations) {
      // const notationWords = words.elements.slice(
      //   notation.address,
      //   notation.endAddress + 1
      // );
      // assume headword always resolved during ingestion
      // if (!notation.headword) {
      //   // default to transcript text if headword not already provided
      //   let wordString = '';
      //   for (const word of notationWords) {
      //     wordString = wordString + word.text + ' ';
      //   }
      //   notation.headword = wordString.trim();
      // }

      // hacked up handling of undesires quotes, etc when headword implicit from usage
      // but don't muck with '_'s explicitly added to canonical headword
      if (notation.headword && !notation.headword.endsWith('_')) {
        notation.headword = stripTrailingPunctuation(notation.headword);
      }

      notation.index = index;
      index++;
    }
    return notations;
  }

  neverPlayed() {
    return this.wordTracker.furthestTrackedPosition() === 0;
  }

  // todo: should perhaps resolve at data load time
  resolveSpeaker(label: string): Speaker {
    if (!label) {
      return null;
    }

    const { storySlug } = this.studyData;
    const story = AppFactory.root.storyManager.story(storySlug);
    if (story) {
      return story.resolveSpeaker(label);
    } else {
      log.error(`missing speaker data for label: ${label}`);
      return null;
    }
  }

  // get complexPlayActionEnabled(): boolean {
  //   // return !this.fluentListenMode;
  //   return AppFactory.root.userManager.userData.playerSettings
  //     .smartPauseEnabled;
  // }

  get playActionDisabled(): boolean {
    return this.atAudioEnd;
  }

  // playPauseAction() {
  //   if (this.complexPlayActionEnabled) {
  //     this.complexPlayPauseAction();
  //   } else {
  //     this.simplePlayPauseAction();
  //   }
  // }

  get translationButtonState(): TranslationButtonState {
    if (this.fluentListenMode) {
      return TranslationButtonState.hidden;
    } else {
      return TranslationButtonState.enabled;
    }
  }

  overrideSentenceRedaction(sentenceId: ElementId) {
    if (this.fluentListenMode) {
      // ignore
    } else {
      super.overrideSentenceRedaction(sentenceId);
    }
  }

  get skipForwardAllowed(): boolean {
    return true;
  }

  get metricsPrefix(): string {
    return 'study';
  }

  get storySlug(): string {
    return this.chapter?.storySlug;
  }

  get sessionDataKey(): string {
    // return `${this.sessionDataKeyPrefix}${randomQualifier()}`;
    // return `${PLAYER_SESSION_BASE_KEY}:${this.storySlug}:${this.sessionCounter}`;
    return [
      PLAYER_SESSION_BASE_KEY,
      this.storySlug,
      this.chapterRef?.unit,
      this.chapterRef?.chapter,
      this.sessionCounter,
    ].join(':');
  }

  get sessionData(): PlayerSessionData {
    const result = {
      playerType: this.playerType,
      storySlug: this.storySlug,
      chapterRef: this.chapterRef,
      sessionCounter: this.sessionCounter,
      completionReached: this.completionReached,
      furthestMillis: this.furthestMillis,
      playerMode: this.playerMode,
      millisPlayed: this.millisPlayed,
      startingMode: this.startingMode,
      timestampIso: new Date().toISOString(),
    };
    return result;
  }

  checkForRecoveryData = (): PlayerSessionData => {
    try {
      const json = localStorage.getItem(this.sessionDataKey);
      if (json) {
        const data: PlayerSessionData = JSON.parse(json);
        log.info(data);
        return data;
      }
    } catch (error) {
      bugsnagNotify(error as Error);
    }
    return null;
  };

  // todo: clean out stale recovery data
  // checkForRecoveryData = () => {
  //   // const { storyManager } = AppFactory.root;
  //   const prefix = this.sessionDataKeyPrefix;
  //   for (let i = 0; i < localStorage.length; i++) {
  //     const key = localStorage.key(i);
  //     if (key.startsWith(prefix)) {
  //       log.warn(`orphaned session data key found: ${key}`);
  //       const json = localStorage.getItem(key);
  //       // localStorage.removeItem(key);
  //       log.info(json);
  //       const data: PlayerSessionData = JSON.parse(json);
  //       if (
  //         data.playerType === PlayerType.STUDY &&
  //         data.chapterRef?.chapter === this.chapterRef?.chapter &&
  //         data.chapterRef?.unit === this.chapterRef?.unit &&
  //         data.sessionCounter === this.sessionCounter
  //       ) {
  //         log.info(
  //           `matching recovery data found, furthestMillis: ${data.furthestMillis}`
  //         );
  //         // this.player.seek(data.furthestMillis);
  //         return data;
  //       } else {
  //         log.warn(
  //           `recovery data does not match current session, this.counter: ${this.sessionCounter}`
  //         );
  //       }
  //     }
  //   }
  //   return null;
  // };
}
