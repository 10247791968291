import React from 'react';
import * as PC from './pricing-cards/pricing-card';
import { Plan } from '@cas-shared/plan';
import { useStripeCheckout } from 'common/stripe-context';
import { formatMoney } from '@cas-shared/format-money';
import { alertSevereError } from '@app/notification-service';
import { capitalize } from 'lodash';
import { AppFactory } from '@app/app-factory';
import { createLogger } from '@common/log';
import { DiscountScheme } from '@cas-shared/cas-types';

import __, { addNbsps } from 'core/lib/localization';

const log = createLogger('plan-pricing-card');

function resolveIntervalLabel(plan: Plan): string {
  switch (plan.billingInterval) {
    case 'month':
      return __('Monthly', 'monthly');
    case 'year':
      return __('Yearly', 'yearly');
    case 'day':
      return 'Daily (testing)'; //__('Daily', 'daily'); // only used for internal testing, not really needed any more
    default:
      log.error(`unexpected billing interval: ${plan.billingInterval}`);
      return capitalize(plan.billingInterval);
  }
}

function resolvePricingExplanation(plan: Plan): string {
  const { isMonthly, isYearly, isDiscounted, normalPrice } = plan;
  let result = isMonthly
    ? __('Billed monthly', 'billedMonthly')
    : isYearly
    ? __('Billed yearly', 'billedYearly')
    : 'Billed daily'; // for testing only

  if (isDiscounted && isMonthly) {
    const formattedNormalPrice = formatMoney(normalPrice, plan.currency);
    result = __('Usually %{price}/mo', 'usuallyNMonthly', {
      price: formattedNormalPrice,
    });
  }
  return result;
}

export const useDerivedPlanVars = (plan: Plan) => {
  const {
    price,
    // pricingDescription,
    discountPercentage,
    isMonthly,
    isYearly,
    isDiscounted,
    currency,
  } = plan;

  // let's curry this to the currency
  const formatCurrency = React.useCallback(
    (n: number) => formatMoney(n, currency),
    [currency]
  );

  const intervalLabel = resolveIntervalLabel(plan);
  const pricingExplanation = resolvePricingExplanation(plan);
  // const isCurrent = userManager.accountData.currentPlan === slug;

  const displayPrice = formatCurrency(
    isMonthly
      ? price
      : isYearly
      ? (price as number) / 12
      : (price as number) * 30 /* daily testing */
  );

  const {
    hasSpecialPricing,
    hasCustomPricingLabel,
    pricingDescription,
    discountScheme,
  } = AppFactory.root.userManager.membership;
  const percentOff = discountScheme?.percentOff || 0;
  const percentOffPrice = percentOff ? price * (1 - percentOff / 100) : 0;
  const percentOffDisplayPrice = formatCurrency(percentOffPrice);

  let ribbonTitle = pricingDescription;
  const displayAsterisk = isYearly && hasSpecialPricing;

  // don't append to custom affiliate pricing ribbon text
  if (!hasCustomPricingLabel) {
    if (hasSpecialPricing && isDiscounted) {
      ribbonTitle = __(
        '%{pricingDescription}: save %{discountPercentage}%',
        'discountedRibbonTitle',
        {
          pricingDescription,
          discountPercentage,
        }
      );
    } else if (isDiscounted) {
      ribbonTitle = __(
        'Save %{discountPercentage}%',
        'savePercentageRibbonTitle',
        {
          discountPercentage,
        }
      );
    }
  }

  if (displayAsterisk) {
    ribbonTitle += ' *';
  }

  const showRibbon = hasSpecialPricing || isDiscounted;
  const showOriginalPrice = isDiscounted && !isMonthly;

  return {
    intervalLabel,
    pricingExplanation,
    displayPrice,
    ribbonTitle,
    showRibbon,
    showOriginalPrice,
    percentOff,
    percentOffPrice,
    percentOffDisplayPrice,
    formatCurrency,
  } as const;
};

const useStripeCheckoutHandler = (plan: Plan) => {
  const stripeCheckout = useStripeCheckout();
  const handleCheckout = React.useCallback(() => {
    const { membership } = AppFactory.root.userManager;
    membership
      .initiateCheckout(plan)
      .then(result => {
        return stripeCheckout(result.stripeSessionId);
      })
      .catch(error => {
        alertSevereError({ error, note: 'PlanPricingCard.handleCheckout' });
      });
  }, [plan, stripeCheckout]);

  return handleCheckout;
};

export const PlanPricingCard = ({
  plan,
  discountScheme,
}: {
  plan: Plan;
  discountScheme: DiscountScheme;
}) => {
  const { price, normalPrice, isMonthly, diminutiveCurrencyPrefix } = plan;

  const {
    intervalLabel,
    pricingExplanation,
    displayPrice,
    ribbonTitle,
    showRibbon,
    showOriginalPrice,
    // percentOff,
    // percentOffPrice,
    formatCurrency,
  } = useDerivedPlanVars(plan);

  const handleCheckout = useStripeCheckoutHandler(plan);

  if (discountScheme) {
    return <DiscountPricingCard plan={plan} discountScheme={discountScheme} />;
  }

  return (
    <PC.Container presentation={isMonthly ? 'monthly' : 'one-year'}>
      {showRibbon ? <PC.Ribbon ribbonTitle={ribbonTitle} /> : null}

      <PC.TextWrapper>
        <PC.Heading>{intervalLabel}</PC.Heading>
        <PC.PriceContainer>
          {!!diminutiveCurrencyPrefix ? (
            <PC.PricePrefix>{diminutiveCurrencyPrefix}</PC.PricePrefix>
          ) : null}
          <PC.PriceAmount>{displayPrice}</PC.PriceAmount>
          <PC.PriceInterval>{__('/mo', 'perMoLower')}</PC.PriceInterval>
        </PC.PriceContainer>

        <PC.Description>
          {showOriginalPrice ? (
            <>
              <PC.Scratch className="cross">
                {formatCurrency(normalPrice)}
              </PC.Scratch>{' '}
            </>
          ) : null}
          {isMonthly ? null : (
            <PC.OfferPrice>{formatCurrency(price)} </PC.OfferPrice>
          )}
          {addNbsps(pricingExplanation)}
        </PC.Description>

        {/* {!!discountScheme ? (
          <PC.Description>
            [{discountScheme.stripeLabel}: {percentOff}% off ={' '}
            {formatCurrency(percentOffPrice)}]
          </PC.Description>
        ) : null} */}
      </PC.TextWrapper>

      <PC.Button
        onClick={handleCheckout}
        label={__('Subscribe', 'subscribe')}
      />
    </PC.Container>
  );
};

// todo: better factor with standard PlanPricingCard
export const DiscountPricingCard = ({
  plan,
  discountScheme,
}: {
  plan: Plan;
  discountScheme: DiscountScheme;
}) => {
  const { userManager } = AppFactory.root;
  const { membership } = userManager;
  const { pricingDescription } = membership;

  const {
    price: planPrice,
    isMonthly,
    isYearly,
    diminutiveCurrencyPrefix,
  } = plan;

  const { intervalLabel, showOriginalPrice, percentOff, formatCurrency } =
    useDerivedPlanVars(plan);

  const ribbonTitle = pricingDescription;

  const price = percentOff ? planPrice * (1 - percentOff / 100) : 0;

  const formattedPlanPrice = formatCurrency(planPrice);
  const pricingExplanation = isYearly
    ? __('Billed yearly', 'billedYearly')
    : __('Usually %{price}/mo', 'usuallyNMonthly', {
        price: formattedPlanPrice,
      });

  const displayPrice = formatCurrency(
    isMonthly
      ? price
      : isYearly
      ? (price as number) / 12
      : (price as number) * 30 /* daily testing */
  );

  const handleCheckout = useStripeCheckoutHandler(plan);

  return (
    <PC.Container presentation={isMonthly ? 'monthly' : 'one-year'}>
      {!!ribbonTitle ? <PC.Ribbon ribbonTitle={ribbonTitle} /> : null}

      <PC.TextWrapper>
        <PC.Heading>{intervalLabel}</PC.Heading>
        <PC.PriceContainer>
          {!!diminutiveCurrencyPrefix ? (
            <PC.PricePrefix>{diminutiveCurrencyPrefix}</PC.PricePrefix>
          ) : null}
          <PC.PriceAmount>{displayPrice}</PC.PriceAmount>
          <PC.PriceInterval>{__('/mo', 'perMoLower')}</PC.PriceInterval>
        </PC.PriceContainer>

        <PC.Description>
          {showOriginalPrice ? (
            <>
              <PC.Scratch className="cross">
                {formatCurrency(planPrice)}
              </PC.Scratch>{' '}
            </>
          ) : null}
          {isMonthly ? null : (
            <PC.OfferPrice>{formatCurrency(price)} </PC.OfferPrice>
          )}
          {addNbsps(pricingExplanation)}
        </PC.Description>
      </PC.TextWrapper>

      <PC.Button
        onClick={handleCheckout}
        label={__('Subscribe', 'subscribe')}
      />
    </PC.Container>
  );
};

export const AnonymousPricingCard = ({ plan }: { plan: Plan }) => {
  const { discountScheme } = AppFactory.root.userManager.membership;
  if (discountScheme) {
    return <AnonymousDiscountPricingCard plan={plan} />;
  } else {
    return <AnonymousPlanPricingCard plan={plan} />;
  }
};

const AnonymousPlanPricingCard = ({ plan }: { plan: Plan }) => {
  const { price, normalPrice, isMonthly, diminutiveCurrencyPrefix } = plan;

  const {
    intervalLabel,
    pricingExplanation,
    displayPrice,
    ribbonTitle,
    showRibbon,
    showOriginalPrice,
    formatCurrency,
  } = useDerivedPlanVars(plan);

  return (
    <PC.Container presentation={'anonymous'}>
      {showRibbon ? <PC.Ribbon ribbonTitle={ribbonTitle} /> : null}

      <PC.TextWrapper>
        <PC.Heading>{intervalLabel}</PC.Heading>
        <PC.PriceContainer>
          {!!diminutiveCurrencyPrefix ? (
            <PC.PricePrefix>{diminutiveCurrencyPrefix}</PC.PricePrefix>
          ) : null}
          <PC.PriceAmount>{displayPrice}</PC.PriceAmount>
          <PC.PriceInterval>{__('/mo', 'perMoLower')}</PC.PriceInterval>
        </PC.PriceContainer>

        <PC.Description>
          {showOriginalPrice ? (
            <>
              <PC.Scratch className="cross">
                {formatCurrency(normalPrice)}
              </PC.Scratch>{' '}
            </>
          ) : null}
          {isMonthly ? null : (
            <PC.OfferPrice>{formatCurrency(price)} </PC.OfferPrice>
          )}
          {addNbsps(pricingExplanation)}
        </PC.Description>
      </PC.TextWrapper>
    </PC.Container>
  );
};

const AnonymousDiscountPricingCard = ({ plan }: { plan: Plan }) => {
  // todo: factor with DiscountPricingCard

  const { userManager } = AppFactory.root;
  const { membership } = userManager;
  const { pricingDescription } = membership;

  const {
    price: planPrice,
    isMonthly,
    isYearly,
    diminutiveCurrencyPrefix,
  } = plan;

  const { intervalLabel, showOriginalPrice, percentOff, formatCurrency } =
    useDerivedPlanVars(plan);

  const ribbonTitle = pricingDescription;

  const price = percentOff ? planPrice * (1 - percentOff / 100) : 0;

  const formattedPlanPrice = formatCurrency(planPrice);
  const pricingExplanation = isYearly
    ? __('Billed yearly', 'billedYearly')
    : __('Usually %{price}/mo', 'usuallyNMonthly', {
        price: formattedPlanPrice,
      });

  const displayPrice = formatCurrency(
    isMonthly
      ? price
      : isYearly
      ? (price as number) / 12
      : (price as number) * 30 /* daily testing */
  );

  return (
    <PC.Container presentation={'anonymous'}>
      {!!ribbonTitle ? <PC.Ribbon ribbonTitle={ribbonTitle} /> : null}

      <PC.TextWrapper>
        <PC.Heading>{intervalLabel}</PC.Heading>
        <PC.PriceContainer>
          {!!diminutiveCurrencyPrefix ? (
            <PC.PricePrefix>{diminutiveCurrencyPrefix}</PC.PricePrefix>
          ) : null}
          <PC.PriceAmount>{displayPrice}</PC.PriceAmount>
          <PC.PriceInterval>{__('/mo', 'perMoLower')}</PC.PriceInterval>
        </PC.PriceContainer>

        <PC.Description>
          {showOriginalPrice ? (
            <>
              <PC.Scratch className="cross">
                {formatCurrency(planPrice)}
              </PC.Scratch>{' '}
            </>
          ) : null}
          {isMonthly ? null : (
            <PC.OfferPrice>{formatCurrency(price)} </PC.OfferPrice>
          )}
          {addNbsps(pricingExplanation)}
        </PC.Description>
      </PC.TextWrapper>
    </PC.Container>
  );
};
