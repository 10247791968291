import * as React from 'react';
import { useNavigate } from 'react-router';
// import { observer } from 'mobx-react';
import { namedObserver } from 'lib/named-observer';
import { Story } from '@core/models/story-manager/story';
import { storySoundbitePath } from 'components/nav/path-helpers';
import { track } from '@app/track';
import { SteppedInterstitialLayout } from './component/stepped-interstitial-layout';
import { Button as InterstitialButton } from './onboarding-interstitial';

import __ from '@core/lib/localization';

export const StoryInterstitialSteps = namedObserver(
  'StoryInterstitialSteps',
  ({ story }: { story: Story }) => {
    const navigate = useNavigate();

    React.useEffect(() => {
      // @joseph we should have different stages here for the two states?
      track('onboarding__interstitial_shown', { stage: 'story' });
    }, []);

    const handleButtonClick = () => {
      story.progress.beginStudying();
      const soundbite = story.firstChapter.firstIncompleteSoundbite;
      const path = storySoundbitePath(story.slug, soundbite?.slug);
      navigate(path);
    };

    return (
      <SteppedInterstitialLayout
        story={story}
        title={__('Listening warm-up', 'listeningWarmUp')}
        copy={__(
          'Your tour of Jiveworld begins with a listening challenge from the story.',
          'yourTourOfJiveworldBeginsWithAListeningChallenge'
        )}
        stage={0}
        renderButton={() => (
          <InterstitialButton
            presentation="teal"
            label={__("Let's go", 'letsGo')}
            onClick={handleButtonClick}
          />
        )}
      />
    );
  }
);
