import __, { pickLocalizedValue } from '@core/lib/localization';
import welcomeImageSrc from './assets/welcome-thumb.png';
import smartPauseImageSrc from './assets/smart-pause-thumb.png';
import studyFlowImageSrc from './assets/study-flow-thumb.png';
import xRayImageSrc from './assets/x-ray-thumb.png';

import ptWelcomeImageSrc from './assets/pt-welcome-thumb.png';
import { VideoGuide } from '@core/models/catalog/video-guide';
import { createLogger } from '@common/log';

const log = createLogger('support/video-guides');

export type TVideoGuide = {
  slug: string;
  title: string;
  videoIdLandscape: string;
  imageUrlLandscape: string;
  durationSeconds: string;
  position: string;
  completed?: boolean;
};

export const resolveVideoGuides = (): VideoGuide[] => {
  log.debug('resolveVideoGuides');
  const datas = getVideoGuidesData();
  const result = datas.map(data => VideoGuide.create(data));
  return result;
};

const getVideoGuidesData = (): TVideoGuide[] => [
  {
    slug: 'welcome',
    title: __('Welcome to Jiveworld', 'videoGuides:title:welcome'),
    videoIdLandscape: pickLocalizedValue({
      en: 'c6667e2375ea4bd789f38d68532c407d',
      pt: 'c6667e2375ea4bd789f38d68532c407d',
    }),
    imageUrlLandscape: pickLocalizedValue({
      en: welcomeImageSrc,
      pt: ptWelcomeImageSrc,
    }),
    durationSeconds: '128',
    position: '1',
  },
  {
    slug: 'smart-pause',
    title: __('Smart Pause', 'videoGuides:title:smart-pause'),
    videoIdLandscape: pickLocalizedValue({
      en: 'c134b01d76e9b25567fb739772cbc829',
      pt: 'c134b01d76e9b25567fb739772cbc829',
    }),
    imageUrlLandscape: smartPauseImageSrc,
    durationSeconds: '74',
    position: '2',
  },
  {
    slug: 'study-flow',
    title: __('Study Flow', 'videoGuides:title:study-flow'),
    videoIdLandscape: pickLocalizedValue({
      en: '3428d562521839011c8200e4c8c06142',
      pt: '3428d562521839011c8200e4c8c06142',
    }),
    imageUrlLandscape: studyFlowImageSrc,
    durationSeconds: '130',
    position: '3',
  },
  {
    slug: 'x-ray',
    title: __('X-ray Vision & Quick Look', 'videoGuides:title:x-ray'),
    videoIdLandscape: pickLocalizedValue({
      en: '2279dd1c1952016feb8fd70f205c00e5',
      pt: '2279dd1c1952016feb8fd70f205c00e5',
    }),
    imageUrlLandscape: xRayImageSrc,
    durationSeconds: '145',
    position: '4',
  },
];
