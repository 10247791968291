import React from 'react';
import { AnonymousPricingCard, PlanPricingCard } from '../plan-pricing-card';
import { Plan } from '@cas-shared/plan';
import { AppFactory } from '@app/app-factory';
import { PricingCardsWrapper } from './pricing-cards-wrapper';
// import { resolvePlans } from '@core/models/user-manager/user-manager-helpers';
import { LocaleCode } from '@utils/util-types';
import { InlineNotice } from '@naan/inline-notice';
import __ from '@core/lib/localization';

interface LoaderState {
  plans?: Plan[];
  failure?: Error;
}

const OfflineNotice = () => {
  return (
    <InlineNotice
      type="alert"
      message={__(
        'You are currently offline. Reconnect and refresh to see pricing.',
        'youAreCurrentlyOfflineReconnectAndRefreshToSeePricing'
      )}
    />
  );
};

export const PricingCards = ({ l2 }: { l2: LocaleCode }) => {
  const { userManager } = AppFactory.root;
  const { pricingGroup } = userManager.accountData;
  const { discountScheme } = userManager.membership;
  // const plans: Plan[] = userManager.resolveLocalPlans(l2);
  // const failure = false;

  const [loaderState, setLoaderState] = React.useState<LoaderState>({});
  const { plans, failure } = loaderState;

  React.useEffect(() => {
    const { userManager } = AppFactory.root;
    userManager
      .resolveNodePlans(l2)
      .then(plans => setLoaderState({ plans }))
      .catch(error => {
        setLoaderState({ failure: error });
      });
  }, [
    l2,
    pricingGroup /* in case student discount activated via email change */,
  ]);

  if (failure) {
    return <OfflineNotice />;
  }

  if (plans) {
    return (
      <PricingCardsWrapper>
        {plans.map(plan => (
          <PlanPricingCard
            key={plan.slug}
            plan={plan}
            discountScheme={discountScheme}
          />
        ))}
      </PricingCardsWrapper>
    );
  }

  return null; // still loading
};

//
// to test this anonymous affiliate pricing, use an url in the form of:
//   http://localhost:3100/?utm_medium=alp&utm_source=fojw
//

export const AnonPricingCards = ({ l2 }: { l2: LocaleCode }) => {
  // const plans: Plan[] = resolvePlans(AppFactory.root, l2);
  // const { userManager } = AppFactory.root;
  // const plans: Plan[] = userManager.resolveLocalPlans(l2);
  // const failure = false;

  const [loaderState, setLoaderState] = React.useState<LoaderState>({});
  const { plans, failure } = loaderState;

  // todo: factor this up with the above
  React.useEffect(() => {
    const { userManager } = AppFactory.root;
    userManager
      .resolveNodePlans(l2)
      .then(plans => setLoaderState({ plans }))
      .catch(error => {
        setLoaderState({ failure: error });
      });
  }, [l2]);

  if (failure) {
    return <OfflineNotice />;
  }

  if (plans) {
    return (
      <PricingCardsWrapper>
        {plans.map(plan => (
          <AnonymousPricingCard plan={plan} />
        ))}
      </PricingCardsWrapper>
    );
  }

  return null; // still loading
};
