/* eslint-disable no-unused-vars */
/// enums trip up eslint, so we disable it for this file. We should consider using objects and `as const` instead of enums
// these types are needed by both tikka and core code and the cross dependency was
// causing a strange jest compile error

export const enum FluentListenStatus {
  IS_STUDYING = 'STUDYING',
  IS_LISTENING = 'IS_LISTENING',
  COMPLETED_LISTEN = 'COMPLETED_LISTEN', // aka fluent listen complete
}

export const enum PlayerMode {
  FLUENT_LISTEN = 'FLUENT_LISTEN',
  STUDY = 'STUDY',
}

export const enum PlayerKind {
  SOUNDBITE = 'SOUNDBITE',
  STUDY = 'STUDY',
  LISTEN = 'LISTEN',
}
