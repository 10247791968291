import * as Dialog from '@naan/primitives/modals/dialog';
import React, { useLayoutEffect } from 'react';
import { ContentGridInnerContainer } from 'components/global-layout';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';

import * as CatalogStuff from './dev-catalog';
import * as Toggles from './dev-toggles';
import * as VideoGuidesStuff from './dev-videoguides';
import * as GeneralInfoStuff from './dev-general-info';
import * as ErrorStuff from './dev-errors';
import * as UsefulLinks from './dev-links';
import * as DataDump from './dev-data-dump';
import * as Logs from './dev-logs';

import { Navigate, NavLink, Route, Routes } from 'react-router-dom';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { IconButton } from '@naan/primitives/button';
import { AppFactory } from '@app/app-factory';

const HamburgerIcon = ({ color = 'currentColor' }) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 6H3V8H21V6ZM3 11H21V13H3V11ZM3 16H21V18H3V16Z"
      fill={color}
    />
  </svg>
);

const screens = [
  GeneralInfoStuff,
  CatalogStuff,
  Logs,
  Toggles,
  VideoGuidesStuff,
  ErrorStuff,
  UsefulLinks,
  DataDump,
  // ChargeebeePoc,
];

const Tabs = styled('div', {
  display: 'flex',
  padding: '24px 0',
  // background: '$gray-50',
  overflow: 'auto',
  width: '100vw',
  margin: '0 -18px',
  scrollSnapType: 'x mandatory',
  position: 'relative',

  '@medium': {
    margin: 0,
    width: 'auto',
  },

  '& a': {
    all: 'unset',
    textStyle: 'body-bold',
    backgroundColor: '$transparent',
    color: '$textSecondary',
    px: '$space$4',
    py: '$space$2',
    borderRadius: '$space$2',
    cursor: 'pointer',
    transition: 'color 0.15s ease, opacity 0.2s ease',
    whiteSpace: 'nowrap',

    '&:hover': {
      color: '$teal-500',
    },
    '&.selected': {
      color: '$teal-600',
      backgroundColor: '$teal-50',
    },
  },

  '&:hover': {
    '& a:not(:hover)': {
      opacity: 0.5,
    },
  },
});

const Wrapper = styled('div', {
  padding: '24px 12px',
  border: '1px solid $gray-50',
  borderRadius: '$space$2',
  width: 'calc(100vw - 48px)',
  overflowX: 'auto',
  '@medium': {
    overflowX: 'visible',
    width: 'auto',
  },
});

function savePath(path: string) {
  localStorage.setItem('devToolsPath', path);
}

function getPath() {
  return localStorage.getItem('devToolsPath') || 'general';
}

function saveIndex(index: number) {
  localStorage.setItem('devToolsTabIndex', String(index));
}

function getIndex() {
  return Number(localStorage.getItem('devToolsTabIndex')) || 0;
}

const ModalWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  position: 'relative',
  '& nav': {
    marginLeft: -16,
    borderRight: '1px solid $gray-50',
    padding: 16,
    '& .nav-inner': {
      position: 'sticky',
      top: 16,
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      '& button': {
        all: 'unset',
        textStyle: 'body-bold',
        backgroundColor: '$transparent',
        color: '$textSecondary',
        padding: '8px 16px',
        borderRadius: '$space$2',
        cursor: 'pointer',
        transition: 'color 0.15s ease, opacity 0.2s ease',
        whiteSpace: 'nowrap',

        '&:hover': {
          color: '$teal-500',
        },
        '&.selected': {
          color: '$teal-600',
          backgroundColor: '$teal-50',
        },
      },
    },
    '&.floating': {
      background: '$gray-50',
      border: '1px solid $gray-100',
      boxShadow:
        '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 0 60px 24px rgba(0, 0, 0, 0.06)',
      position: 'absolute',
      left: 8,
      top: 4,
      // paddingLeft: 64,
      width: 300,
      zIndex: 1,
      transform: 'translate3d(-100%, 0, 0)',
      transition: 'transform 0.2s ease-in-out',
      '&.open': {
        transform: 'translate3d(0, 0, 0)',
      },
      '& .nav-inner': {
        '& button': {
          padding: '12px 24px',
        },
      },
    },
  },
  '& .tab-content': {
    padding: 16,
  },
});

const ModalHeadingWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 16,
});

export const DevToolsModal = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    const [currentTabIndex, setCurrentTabIndex] = React.useState(getIndex());

    const Content = React.useMemo(() => {
      const currentScreen = screens[currentTabIndex];
      const Content = currentScreen.Component;
      return Content;
    }, [currentTabIndex]);

    const isMobile = useMediaQuery({
      query: '(max-device-width: 550px)',
    });

    const enableFloatingNav = isMobile;
    const navOpen = useSwitch2(false);

    return (
      <Dialog.Container
        open
        onDismiss={onDismiss}
        css={{
          width: 'min(100vw - 32px, 1024px)',
          height: 'max(100vh - 32px, 768px)',
        }}
      >
        <Dialog.Heading withBorder>
          <ModalHeadingWrapper>
            {enableFloatingNav ? (
              <IconButton
                onClick={() => navOpen.toggle()}
                icon={<HamburgerIcon />}
                presentation="grayTransparent"
                type="button"
                tabIndex={-1}
                testId={''}
              />
            ) : null}
            Dev tools
          </ModalHeadingWrapper>
        </Dialog.Heading>
        <Dialog.CloseButton />
        <Dialog.Body>
          <ModalWrapper>
            <nav
              className={classNames({
                floating: enableFloatingNav,
                open: navOpen.value,
              })}
            >
              <div className="nav-inner">
                {screens.map((screen, index) => (
                  <button
                    key={index}
                    className={classNames('tab', {
                      selected: currentTabIndex === index,
                    })}
                    onClick={() => {
                      setCurrentTabIndex(index);
                      saveIndex(index);
                    }}
                  >
                    {screen.label}
                  </button>
                ))}
              </div>
            </nav>
            <div className="tab-content">
              <Content />
            </div>
          </ModalWrapper>
        </Dialog.Body>
      </Dialog.Container>
    );
  }
);

export const presentDevToolsModal = () => {
  AppFactory.dialogPresenter.present(onDismiss => (
    <DevToolsModal onDismiss={onDismiss} />
  ));
};

// (window as any).devTools = presentDevToolsModal;

export const DevToolsScreen = observer(() => {
  useLayoutEffect(() => {
    // scroll .selected into view
    const selected = document.querySelector('.selected');
    if (selected) {
      selected.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  return (
    <ContentGridInnerContainer>
      <Tabs>
        {screens.map((screen, index) => (
          <NavLink
            key={index}
            className={({ isActive }) => (isActive ? 'selected' : '')}
            to={screen.path}
            onClick={() => savePath(screen.path)}
          >
            {screen.label}
          </NavLink>
        ))}
      </Tabs>
      <Wrapper>
        <Routes>
          <Route path="/" element={<Navigate to={getPath()} />} />
          {screens.map((screen, index) => (
            <Route
              key={index}
              path={screen.path}
              element={<screen.Component />}
            />
          ))}
        </Routes>
      </Wrapper>
    </ContentGridInnerContainer>
  );
});
