import React from 'react';
import { observer } from 'mobx-react';
// import { MediumHeading } from 'naan/primitives/text';

import { LargeGap } from '@naan/primitives/spacer/gaps';
import { VSpacer } from 'naan/primitives/spacer';
import { formatDate } from 'utils/format-date';
import { AppFactory } from '@app/app-factory';
import { PricingCards } from '../pricing-cards';
import { PlainMarkdown } from '@naan/primitives/text';
import { daysCount } from '@core/lib/localization-helpers';
import { LocaleCode } from '@utils/util-types';
import {
  Container,
  Label,
  LeftSide,
  RightSide,
  Row,
  Value,
} from './components';
import { Button } from '@naan/primitives/button';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { styled } from '@naan/stitches.config';
import { useAutoanimate } from '@common/hooks/use-autoanimate';
import { PriceIncreaseSubscribeCta } from 'routes/profile/price-increase-subscribe-cta';

import __ from 'core/lib/localization';
import { Membership } from '@core/models/user-manager/membership';

const ZippyWrapper = styled('div', {
  '& .message': {
    textStyle: 'body',
    '& h3': {
      padding: '8px 0',
      textStyle: 'small-heading',
    },
  },
});

export const ExtendZippy = observer(
  ({
    expanded,
    membership,
    showPrinceIncreaseMessage,
    l2,
  }: {
    expanded: boolean;
    membership: Membership;
    showPrinceIncreaseMessage: boolean;
    l2: LocaleCode;
  }) => {
    const parent = useAutoanimate({
      duration: 300,
    });
    // const showMessage = membershipData.remainingFullAccessDays < 90;

    const showMessage =
      membership.showAccountPageCheckout && !showPrinceIncreaseMessage;
    const remainingDays = membership.remainingFullAccessInDays;

    return (
      <div ref={parent}>
        <PriceIncreaseSubscribeCta flavor="fullAccess" />
        {expanded ? (
          <ZippyWrapper>
            {showMessage ? (
              <div className="message">
                <h3>{__('Extend membership', 'extendMembership')}</h3>
                {remainingDays > 0 ? (
                  <PlainMarkdown
                    source={__(
                      'By extending your membership, you will be starting a new subscription. You will **not** lose your remaining access ({{duration}}) -- these will appear as "trial days" on the next screen, before your first charge.',
                      'extendMembershipInfo',
                      {
                        duration: daysCount(remainingDays),
                      }
                    )}
                  />
                ) : null}
              </div>
            ) : null}
            <VSpacer size={6} />
            <PricingCards l2={l2} />
            <VSpacer size={6} />
          </ZippyWrapper>
        ) : null}
      </div>
    );
  }
);

export const FullNoRenew = observer(({ l2 }: { l2: LocaleCode }) => {
  // we no longer show pricing cards while access is still active
  const changing = useSwitch2(false);

  const { userManager } = AppFactory.root;
  const membership = userManager.membershipL2(l2);
  const {
    membershipTypeDisplay,
    fullAccessUntil,
    showAccountPageCheckout,
    statusKey,
  } = membership;
  const isExpired = statusKey === 'expired';

  if (userManager.purchaseFlowDisabled) {
    return null;
  }

  const autoExpand = membership.showPriceIncreaseInlineNotice;
  const showPrinceIncreaseMessage = membership.showPriceIncreaseInlineNotice;

  return (
    <>
      {/* <MediumHeading>{__('My membership', 'myMembership')}</MediumHeading>
        <VSpacer size="4" /> */}
      <Container>
        <Row>
          <Label>{__('Membership type', 'membershipType')}</Label>
          <Value> {membershipTypeDisplay}</Value>
        </Row>

        <Row>
          <LeftSide>
            <Label>
              {isExpired
                ? __('Expired on', 'expiredOn')
                : __('Expires', 'expires')}
            </Label>
            <Value>{formatDate(fullAccessUntil)}</Value>
          </LeftSide>
          <RightSide>
            {autoExpand === false && showAccountPageCheckout ? (
              <Button
                presentation={changing.value ? 'grayLight' : 'teal'}
                size="small"
                onClick={changing.toggle}
                label={
                  changing.value ? __('Close', 'close') : __('Extend', 'extend')
                }
              ></Button>
            ) : null}
          </RightSide>
        </Row>

        {showAccountPageCheckout ? (
          <ExtendZippy
            l2={l2}
            showPrinceIncreaseMessage={showPrinceIncreaseMessage}
            membership={membership}
            expanded={autoExpand === true ? true : changing.value}
          />
        ) : null}
      </Container>
      <LargeGap />
    </>
  );
});
