import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { styled } from '@naan/stitches.config';
import { LocaleCode } from '@utils/util-types';
import { AppFactory } from '@app/app-factory';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { FullyStyledMarkdown } from '@naan/primitives/text';
import __, {
  pickLocalizedValue,
  translateWithoutDefault,
} from '@core/lib/localization';

import jwEspanolLogo from '../../../components/branding/jw-espanol-full-logo.png';
import jwEnglishLogo from '../../../components/branding/jw-english-full-logo.png';
import { Image } from '@naan/primitives/image';
// import { appConfig } from '@app/env';

// beware, styling blindly copied from settings-modal
const Container = styled(Dialog.Container, {
  width: 'min( 600px, calc(100% - 32px) )',
});

const logoMap = { en: jwEnglishLogo, es: jwEspanolLogo };
type logoKey = keyof typeof logoMap;

const productBlurb = (l2: LocaleCode) => {
  // switch (l2) {
  //   case 'en':
  //     return __(
  //       'A paragraph of text about Jiveworld English, blah blah [placeholder]',
  //       'productInfoModalBlurb:en'
  //     );
  //   default:
  //     return __(
  //       `A paragraph of text about Jiveworld Espanol and Radio Ambulante, etc [placeholder]`,
  //       'productInfoModalBlurb:es'
  //     );
  // }
  return translateWithoutDefault(`cms:productInfoModalBlurb:${l2}`);
};

const L2SwitcherContainer = styled(Container, {
  width: 'min(100% - 32px, 420px)',
});

const LogoWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  padding: '40px 0',
  '& img': {
    // width: '100%',
    maxWidth: 204,
  },
});

const Blurb = styled('p', {
  textStyle: 'body',
  marginBottom: 24,
  '& p': {
    marginBottom: '1rem',
  },
});

export const L2InfoSwitchModal = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <L2SwitcherContainer open onDismiss={onDismiss}>
      {/* <HeadingWrapper>
        <span className="title">{productName}</span>
      </HeadingWrapper> */}

      <Dialog.Body>
        <LogoWrapper>
          <Image src={logoMap[AppFactory.root.l2 as logoKey]} />
        </LogoWrapper>
        <Blurb>
          <FullyStyledMarkdown
            source={
              productBlurb(AppFactory.root.l2) +
              '\n\n' +
              __(
                'Learn more at [jiveworld.com](%{url})',
                'learnMoreAtJiveworldCom',
                {
                  url: pickLocalizedValue({
                    en: 'https://www.jiveworld.com/en/',
                    pt: 'https://www.jiveworld.com/pt/',
                  }),
                }
              )
            }
          />
        </Blurb>
        <SwitchLanguageLink />
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          presentation="grayLight"
          label={__('Close', 'close')}
          onClick={onDismiss}
        />
      </Dialog.ButtonsContainer>
    </L2SwitcherContainer>
  );
};

const SwitcherWrapper = styled('div', {
  display: 'flex',
  padding: 16,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: 8,
  border: '1px solid $gray-100',
  textStyle: 'small-text-bold',
  color: '$textSecondary',
  '& button': {
    all: 'unset',
    textStyle: 'small-text',
    color: '$teal-500',
    cursor: 'default',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const SwitchLanguageLink = () => {
  // if (appConfig.forcedL2) {
  //   // omit l2 switch when forced (10.x release configuration)
  //   return null;
  // }

  const { l2 } = AppFactory.root;
  let text: string;
  let linkLabel: string;
  if (l2 === 'en') {
    text = 'Looking for Jiveworld Español?';
    linkLabel = 'Switch language';
  } else {
    text = 'Procurando pelo Jiveworld English?';
    linkLabel = 'Mudar idioma';
  }
  return (
    <SwitcherWrapper>
      <p>{text}</p>
      <button
        onClick={() => {
          ReturnNavState.clearL2Cookie();
          window.location.href = '/';
        }}
      >
        {linkLabel}
      </button>
    </SwitcherWrapper>
  );
};

export const presentL2InfoSwitchModal = () => {
  AppFactory.dialogPresenter.present(onDismiss => (
    <L2InfoSwitchModal onDismiss={onDismiss} />
  ));
};
