import * as React from 'react';
import autoAnimate from '@formkit/auto-animate';
import { ChapterCatalogData } from '@core/models/catalog';
import { useChapterRowUiContext } from './chapter-row-ui-model';
import { Button } from '@naan/primitives/button';
import { observer } from 'mobx-react';
import { useNavigateToStudy } from 'lib/hooks/use-navigate-to-study';
import { PlayerMode } from '@common/misc-types';
import { ArrowLeftIcon } from '@naan/icons/arrow-left-icon';
// import { AppFactory } from '@app/app-factory';
import { LocationPointer } from '@core/models/user-manager/location-pointer';
import { appConfig } from '@app/config';
import { storyDetailUiModel } from 'components/story-detail/story-detail-ui-model';

import __ from '@core/lib/localization';

const getButtonLabel = (
  mode: 'study' | 'listen' | 'whoa' | 'completed',
  midListen: boolean,
  currentPoint?: LocationPointer
) => {
  switch (mode) {
    case 'study':
      if (midListen) {
        return __('Continue studying', 'continueStudying');
      } else {
        if (currentPoint.studyComplete) {
          return __('Study again', 'studyAgain');
        }
        return __('Begin studying chapter', 'beginStudyingChapter');
      }
    case 'listen':
      if (midListen) {
        return __('Continue listening', 'continueListening');
      } else {
        if (currentPoint.listenComplete) {
          return __('Listen again', 'listenAgain');
        }
        return __('Begin listening', 'beginListening');
      }
    case 'whoa':
      return __('Go back', 'goBack');
    case 'completed':
      return __('Mark chapter complete', 'markChapterComplete');
    default:
      break;
  }
};

type ButtonPresentation = typeof Button['defaultProps']['presentation'];

export const ChapterActionButton = observer(
  ({
    chapter,
    viaDetail,
    presentation,
  }: {
    chapter: ChapterCatalogData;
    viaDetail: boolean;
    presentation?: ButtonPresentation;
  }) => {
    const navigateToStudy = useNavigateToStudy();
    const model = useChapterRowUiContext();
    const { currentStudyMode: mode } = model;
    const whoa = mode === 'completed' && !chapter.isFirstListenComplete;

    const onButtonClick = React.useCallback(() => {
      const mode = whoa ? 'whoa' : model.currentStudyMode;

      function completeActionSequence() {
        void storyDetailUiModel
          .imperativeCollapseChapterContainer()
          .then(() => {
            // we wait for close animation to complete, then...
            setTimeout(() => {
            // this sets a flag in the story model so the next chapter will start collapsed
            storyDetailUiModel.setPendingExpandTransition(true);
            // this marks the story as complete which will autmatically advance to the next story
            chapter.markComplete();
            }, 300);
          });
      }

      switch (mode) {
        case 'study':
          navigateToStudy(
            chapter,
            PlayerMode.STUDY,
            chapter.currentMillis,
            viaDetail
          );
          break;

        case 'listen':
          navigateToStudy(
            chapter,
            PlayerMode.FLUENT_LISTEN,
            chapter.currentMillis,
            viaDetail
          );

          // const { userSettings } = AppFactory.root.userManager.userData;
          // userSettings.dismissTip('onboardingComplete');
          // story.progress.markCurrentChapterComplete();

          break;

        case 'whoa':
          /// allows us devs to mark chapter complete without listening to it
          if (appConfig.disableWhoaThere) {
            completeActionSequence();
            break;
          }
          model.setCurrentStudyMode('study');
          break;

        case 'completed':
          completeActionSequence();
          // if (chapter.isLastOne) {
          //   maybePresentEndOfStoryCtaDialog();
          // }
          break;
      }
    }, [chapter, model, navigateToStudy, whoa, viaDetail]);

    const parent = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
      parent.current &&
        autoAnimate(parent.current, {
          duration: 150,
          easing: 'ease-out',
        });
    }, [parent]);

    const buttonMode = whoa ? 'whoa' : model.currentStudyMode;
    const isPlayerAction = ['study', 'listen'].includes(buttonMode);
    const midListen = chapter.currentMillis > 0 && isPlayerAction;
    if (!presentation) {
      presentation = isPlayerAction
        ? buttonMode === 'study'
          ? 'tealProgress'
          : 'grayDarkProgress'
        : 'contextual';
    }

    return (
      <Button
        leftIcon={whoa ? <ArrowLeftIcon /> : null}
        // presentation="contextual"
        presentation={presentation}
        onClick={onButtonClick}
        label={getButtonLabel(buttonMode, midListen, chapter.currentPoint)}
        size="extraLarge"
        css={{
          $$currentProgress: midListen ? `${chapter.currentPercentage}%` : '0%',
        }}
        fullWidth
      />
    );
  }
);
