import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { keyframes, styled } from '@naan/stitches.config';
import { AppFactory } from '@app/app-factory';
import { GeneralSettingsTab } from './general-settings-tab';
import { useRadio } from '@common/hooks/use-radio';
import { PlayerSettingsTab } from './player-settings-tab';
import { DialogHtmlContainer } from 'components/ui/dialog-html-container';

import __ from '@core/lib/localization';

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.75)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const Heading = styled('div', {
  display: 'flex',
  padding: '16px 16px 8px',
  textStyle: 'small-heading',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',

  // '&::after': {
  //   content: '""',
  //   display: 'block',
  //   height: '1px',
  //   backgroundColor: '$gray-100',
  //   position: 'absolute',
  //   bottom: 0,
  //   left: 16,
  //   right: 16,
  // },
});

const Content = styled(Dialog.HtmlContent, {
  $$maxWidth: '600px',
  $$maxHeight: '670px',
  backgroundColor: '$white',
  borderRadius: 12,
  boxShadow: `0px 1px 4px $colors$black-alpha-20`,
  position: 'fixed',
  top: 'calc(50% + (var(--sat) / 2) - (var(--sab) / 2))',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(calc(100% - 32px), $$maxWidth)',
  height: 'calc(100% - 32px - var(--sat) - var(--sab))',
  maxHeight: 'none',
  display: 'flex',
  flexDirection: 'column',
  '@medium': {
    maxHeight: 'min(calc(100% - 32px - var(--sat) - var(--sab)), $$maxHeight)',
  },
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 0.5s cubic-bezier(0.16, 1, 0.3, 1)`,
    willChange: 'transform',
  },
  '&:focus': { outline: 'none' },
});

const TabsNav = styled('div', {
  display: 'flex',
  borderBottom: '1px solid $colors$gray-100',
  margin: '0 16px',
  '& > button': {
    $$color: '$colors$textSecondary',
    $$indicatorColor: '$colors$transparent',
    all: 'unset',
    marginBottom: -1,
    flex: 1,
    textStyle: 'body-bold',
    padding: '16px 0 12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '$$color',
    borderBottom: '4px solid $$indicatorColor',
    '&:hover': {
      $$color: '$colors$teal-500',
      // $$indicatorColor: '$colors$teal-500',
    },
    '&.active': {
      $$color: '$colors$teal-500',
      $$indicatorColor: '$colors$teal-500',
    },
  },
});

const tabs = ['General', 'Player'] as const;
type SettingsTab = typeof tabs[number];

export const SettingsModal = observer(
  ({
    onDismiss,
    initialTab = 'General',
  }: {
    onDismiss: () => void;
    initialTab?: SettingsTab;
  }) => {
    const tabState = useRadio<SettingsTab>(initialTab);

    return (
      <DialogHtmlContainer
        onDismiss={onDismiss}
        contentComponent={Content as any}
      >
        <Heading>
          {__('Settings', 'settings')}
          <span className="close-btn">
            <Dialog.HtmlCloseButton onDismiss={onDismiss} />
          </span>
        </Heading>
        <TabsNav>
          <button
            className={tabState.currentTab === 'General' ? 'active' : ''}
            onClick={() => tabState.setCurrentTab('General')}
          >
            {__('General', 'general')}
          </button>
          <button
            className={tabState.currentTab === 'Player' ? 'active' : ''}
            onClick={() => tabState.setCurrentTab('Player')}
          >
            {__('Player', 'player')}
          </button>
        </TabsNav>
        <Dialog.Body>
          <div className="tabs-content">
            {tabState.currentTab === 'General' ? <GeneralSettingsTab /> : null}
            {tabState.currentTab === 'Player' ? <PlayerSettingsTab /> : null}
          </div>
        </Dialog.Body>
      </DialogHtmlContainer>
    );
  }
);

export const presentSettingsModal = (initialTab: SettingsTab = 'General') => {
  AppFactory.dialogPresenter.present(onDismiss => (
    <SettingsModal onDismiss={onDismiss} initialTab={initialTab} />
  ));
};
