import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { VSpacer } from '@naan/primitives/spacer';

import __ from '@core/lib/localization';
import { ExternalLink } from '@naan/primitives/text/external-link';
import { eulaUrl, privacyUrl, tosUrl } from 'components/nav/path-helpers';

const Wrapper = styled('div', {
  $$textColor: '$colors$white-alpha-70',
  textStyle: 'small-text',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  color: '$$textColor',
  '& .links': {
    display: 'flex',
    gap: '4px',
    '& a': {
      color: 'inherit',
    },
  },
  '& .copy': {
    maxWidth: 420,
  },
  variants: {
    presentation: {
      light: {
        $$textColor: '$colors$textSecondary',
      },
    },
  },
});

type WrapperProps = React.ComponentProps<typeof Wrapper> & {
  showLinks?: boolean;
  specialPricingNotice?: boolean;
};

export const Footer = ({
  showLinks = true,
  specialPricingNotice = false,
  ...props
}: WrapperProps) => (
  <Wrapper {...props}>
    {specialPricingNotice ? (
      <div className="copy">
        *{' '}
        {__(
          'Compared to annualized regular monthly price',
          'comparedToAnnualized'
        )}
        <VSpacer size={2} />
      </div>
    ) : null}
    <div className="copy">
      {__(
        `You can cancel anytime; you won't be charged again, and will retain full access until the end of the current cycle.`,
        'youCanCancelAnytimeYouWontBeChargedAgain'
      )}
    </div>
    <VSpacer size={2} />
    {showLinks ? (
      <div className="links">
        <ExternalLink href={tosUrl()}>
          {__('Terms of service', 'termsOfService')}
        </ExternalLink>
        <span>•</span>
        <ExternalLink href={privacyUrl()}>
          {__('Privacy policy', 'privacyPolicy')}
        </ExternalLink>
        <span>•</span>
        <ExternalLink href={eulaUrl()}>
          {__('User License Agreement', 'userLicenseAgreement')}
        </ExternalLink>
      </div>
    ) : null}
  </Wrapper>
);
