import * as React from 'react';

import cx from 'classnames';
import { RedactionMode } from 'player/models/redaction-modes';
import { WordMembership } from '../../../player/models/word-membership';
import { WordElementProps } from './element-props';
import { Spacer } from './spacer';
import { namedStyled } from 'naan/stitches.config';
import { stripTrailingPunctuation } from '@tikka/misc/string-utils';
import { paletteKey } from '../../../study/views/study-palette';

const WordWrapper = namedStyled('WordWrapper', 'div', {
  display: 'inline',
  lineHeight: '1.5em',
  backgroundColor: 'transparent',
  $$redactedBg: paletteKey('$$redacted__backgroundColor_unvisited'),
  $$textColor: paletteKey('$$word__color_default'),

  // $$afterWordRedactedBg: paletteKey('$$redacted__backgroundColor_unvisited'),
  $$afterWordRedactedBg: 'orange',
  // $$afterWordTextColor:paletteKey('$$word__color_default'),
  $$afterWordTextColor: 'red',

  '&.selected': {
    backgroundColor: '#ffed8f',
  },

  '& > .outer': {
    display: 'inline-block',
    color: 'transparent',
    position: 'relative',
    background: 'transparent',

    '& > .word': {
      display: 'inline-block',
      color: '$$textColor',
      opacity: 0,
      userSelect: 'none',
      // transition: 'opacity .15s, color .2s',
      // borderBottom: '1.5px solid $$border-color)',
      '&.italic': {
        fontStyle: 'italic',
      },
      '& .sic-asterisk': {
        color: paletteKey('$$word__color_sic_visited'),
      },
      '&.sic-original': {
        // fontStyle: 'italic',
        color: '$$textColor',
        '&.visited': {
          // $$textColor: paletteKey('$$word__color_sic_visited'),
          '& > .sic-asterisk': {
            color: paletteKey('$$word__color_sic_visited'),
          },
        },
      },
      '&.sic-intended': {
        fontStyle: 'italic',
        // $$textColor: paletteKey('$$word__color_sic_visited'),
        // color: '$$textColor',

        '&.visited': {
          $$textColor: paletteKey('$$word__color_sic_visited'),
        },
      },
    },

    '& > .redact-bar': {
      // transition: 'opacity 1s, background .2s,',
      opacity: 1,
      background: '$$redactedBg',
      position: 'absolute',
      height: '9px',
      width: '100%',
      left: '0px',
      borderRadius: '3px',
      top: '10px',
    },

    '&.show-word': {
      '& > .word': {
        opacity: 1,
      },
      '& > .redact-bar': {
        opacity: 0,
      },
    },

    '&.visited': {
      $$redactedBg: paletteKey('$$redacted__backgroundColor_visited'),
      $$textColor: paletteKey('$$word__color_visited'),
    },
    '&.remaining': {
      // $$redactedBg: '$colors$red-70',
      $$redactedBg: paletteKey('$$redacted__backgroundColor_remaining'),
      $$textColor: '$colors$red-100',
      // '&.current': {
      //   backgroundColor: '#ff0000',
      // },
    },
    '&.current': {
      $$redactedBg: paletteKey('$$redacted__backgroundColor_current'),
      $$textColor: '$colors$teal-500',
    },
  },

  // '.pending-pause &:has(.current) ~ &': {
  //   $$textColor: '$colors$red-100',
  //   $$redactedBg: '$colors$red-70',
  // },
  '.pending-pause &:has(.current)': {
    '.redact-bar': {
      // $$redactedBg: '$colors$red-400',
      $$redactedBg: paletteKey('$$redacted__backgroundColor_pendingCurrent'),
    },
    '.word': {
      $$textColor: '$colors$red-500',
    },
    // border: '1px solid red !important',
    // backgroundColor: 'cyan !important',
  },

  '.pending-pause &:last-child': {
    // background: 'orange',
    '& .word-gap': {
      width: 8,
      height: 9,
      marginRight: 9,
      position: 'relative',
      top: -1,
      left: 5,
      // background: 'red',
      borderLeft: '3px solid $colors$red-500',
      borderRight: '3px solid $colors$red-500',
      '& svg': {
        display: 'none',
      },
    },
  },
});

const WordText = namedStyled('Wordtext', 'span', {
  $$borderColor: 'transparent',
  display: 'inline-block',
  // color: '#b4d455',
  // borderBottom: '1.5px solid $$border-color)',
  '&.italic': {
    fontStyle: 'italic',
  },
});

WordText.displayName = 'WordText';

const RedactedOuter = namedStyled('RedactedOuter', 'span', {});

export const getSicTextTrailingPunctuation = (text = '') => {
  const withPunctuation = text;

  // todo: confirm/document exactly why we're stripping trailing punctuation here.
  text = stripTrailingPunctuation(text);

  const trailing =
    text !== withPunctuation
      ? withPunctuation.slice(text.length, withPunctuation.length)
      : '';

  return trailing;
};

// Reusable component for rendering words
const RenderWord = ({
  word,
  membership,
  showWord,
  isSicIntended = false,
  sicStart = false,
}: {
  word: string;
  membership: number;
  showWord: boolean;
  isSicIntended?: boolean;
  sicStart?: boolean;
}) => (
  <WordWrapper key={word}>
    <RedactedOuter
      className={cx('outer', {
        visited: membership & WordMembership.VISITED,
        current: membership & WordMembership.CURRENT,
        remaining: membership & WordMembership.SMART_REMAINING,
        'show-word': showWord,
      })}
    >
      {isSicIntended ? null : <span className="redact-bar" />}
      <span
        className={cx([
          'word',
          {
            'sic-intended': isSicIntended,
            italic: membership & WordMembership.ITALIC,
            visited: membership & WordMembership.VISITED,
          },
        ])}
      >
        {sicStart ? <span className="sic-asterisk">*</span> : null}

        {word}
      </span>
    </RedactedOuter>
    <Spacer membership={isSicIntended ? 0 : membership} />
  </WordWrapper>
);

export const SicContent = ({
  text: originalText,
  membership,
  sicIntended = null,
  showWord = false,
}: Pick<
  WordElementProps,
  'membership' | 'sicIntended' | 'sicStart' | 'text'
> & { showWord: boolean }) => {
  if (!sicIntended) {
    return null;
  }

  const trailing = getSicTextTrailingPunctuation(originalText);

  const sicWords = sicIntended.split(/\s+/);
  sicWords[0] = '[' + sicWords[0];
  const sicWordsLast = sicWords.length - 1;
  sicWords[sicWordsLast] = sicWords[sicWordsLast] + ']' + trailing;

  return (
    <span>
      {sicWords.map((sicWord: string) => {
        return (
          <RenderWord
            word={sicWord}
            membership={membership}
            showWord={showWord}
            isSicIntended={true}
          />
        );
      })}
    </span>
  );
};

export const WordPresentation = ({
  text,
  membership,
  redaction,
  sicStart = false,
  sicIntended = null,
}: WordElementProps) => {
  // let showWord = !!(membership & WordMembership.VISITED);

  // eng-2432: experimentally unredact unvisited words
  let showWord = true;

  // if (sicStart) {
  //   text = '*' + text;
  // }
  if (sicIntended) {
    // todo: confirm/document exactly why we're stripping trailing punctuation here.
    text = stripTrailingPunctuation(text);
  }

  if (!(membership & WordMembership.SIC)) {
    if (redaction === RedactionMode.SHOW_NONE) {
      showWord = false;
    } else if (redaction === RedactionMode.SHOW_ALL) {
      // showWord already always set correctly
    } else if (redaction === RedactionMode.SHOW_SOME) {
      if (
        !(
          membership & WordMembership.NOTATION ||
          membership & WordMembership.TRICKY
        ) &&
        showWord
      ) {
        showWord = false;
      }
    }
  }
  if (membership & WordMembership.SELECTED_NOTATION) {
    showWord = true;
  }

  return (
    <>
      <RenderWord
        word={text}
        membership={membership}
        showWord={showWord}
        sicStart={sicStart}
      />
      <SicContent
        text={text}
        membership={membership}
        sicIntended={sicIntended}
        showWord={showWord}
      />
    </>
  );
};
