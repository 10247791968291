import * as React from 'react';
import { SearchIcon } from 'naan/icons/search-icon';
import { styled } from 'naan/stitches.config';
import { Button, IconButton } from 'naan/primitives/button';

import { ClearIcon } from 'naan/icons/clear-icon';
import { FilterButton } from 'components/filters/filter-button';
import { Responsive } from 'naan/primitives/responsive';
import { useSwitch2 } from '@naan/hooks/use-switch-2';

import __ from 'core/lib/localization';

// const VisuallyHidden = styled('div', {
//   clip: 'rect(0 0 0 0)',
//   clipPath: 'inset(50%)',
//   height: '1px',
//   overflow: 'hidden',
//   position: 'absolute',
//   whiteSpace: 'nowrap',
//   width: '1px',
// });

const SearchInputContainer = styled('div', {
  backgroundColor: '$white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'absolute',
  top: '$space$2',
  left: 0,
  right: 0,
  '@medium': {
    top: 0,
    height: '100%',
    // marginRight: '220px',
    maxWidth: '660px',
  },
});

const FakeInput = styled('div', {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '$gray-50',
  borderRadius: '$space$2',
  // px: '$3',
  height: '$space$10',
  '@medium': {
    height: '$space$12',
  },
  '& .search-icon': {
    margin: 0,
    padding: 0,
    marginLeft: '$space$3',
    lineHeight: 0,
    color: '$gray-400',
    marginRight: '$1',
  },
  '& input': {
    all: 'unset',
    display: 'block',
    border: 'none',
    caretColor: '$teal-500',
    flex: 1,
    height: 40,
    '@medium': {
      textStyle: 'small-heading',
      height: 48,
    },
  },
  '& .clear-icon': {
    margin: 0,
    padding: 0,
    lineHeight: 0,
    color: '$gray-400',
    marginRight: '-$3',
    '&.hidden': {
      opacity: 0,
    },
  },
});

type SearchBoxProps = {
  initialQuery: string | null;
  onChangeQuery: (searchQuery?: string) => void;
  onClearQuery: () => void;
  onEnterSearchMode: () => void;
  onExitSearchMode: () => void;
};

const GoIcon = () => {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 26C20.6274 26 26 20.6274 26 14C26 7.37258 20.6274 2 14 2C7.37258 2 2 7.37258 2 14C2 20.6274 7.37258 26 14 26ZM16.5858 15L13.7929 17.7929L15.2071 19.2071L19.7071 14.7071L20.4142 14L19.7071 13.2929L15.2071 8.79289L13.7929 10.2071L16.5858 13H8V15H16.5858Z"
        fill="currentColor"
      />
    </svg>
  );
};

const SearchBoxState = {
  NOT_SEARCHING: 'NOT_SEARCHING',
  SEARCH_APPLIED: 'SEARCH_APPLIED',
  SEARCH_PENDING: 'SEARCH_PENDING',
} as const;

export const SearchBox = ({
  initialQuery,
  onChangeQuery,
  onClearQuery,
  onEnterSearchMode,
  onExitSearchMode,
}: SearchBoxProps) => {
  const searchingSwitch = useSwitch2(!!initialQuery);
  const [pendingSearch, setPendingSearch] = React.useState<string | null>(
    initialQuery
  );
  const inputRef = React.useRef<HTMLInputElement>(null);

  const searchStatus = pendingSearch
    ? pendingSearch === initialQuery
      ? SearchBoxState.SEARCH_APPLIED
      : SearchBoxState.SEARCH_PENDING
    : SearchBoxState.NOT_SEARCHING;

  React.useEffect(() => {
    if (searchingSwitch.value) {
      inputRef.current?.focus();
      onEnterSearchMode();
    }
  }, [onEnterSearchMode, onExitSearchMode, searchingSwitch.value]);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // const query = inputRef.current?.value;
    // // console.log(query);
    if (pendingSearch) {
      onChangeQuery(pendingSearch);
    }
  };

  const handleCancel = React.useCallback(() => {
    setPendingSearch(null);
    searchingSwitch.off();
    onExitSearchMode();
  }, [onExitSearchMode, searchingSwitch]);

  const handleLiveSearch = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      setPendingSearch(value);
    },
    [setPendingSearch]
  );

  const handleClear = React.useCallback(
    e => {
      e.preventDefault();
      setPendingSearch(null);
      onClearQuery();
      inputRef.current?.focus();
      // if (inputRef.current) {
      //   inputRef.current.value = '';
      //   setShowClearButton(false);
      //   onClearQuery();
      // }
    },
    [onClearQuery]
  );

  return (
    <>
      <Responsive
        renderDefault={() => (
          <FilterButton
            onClick={searchingSwitch.on}
            label={<SearchIcon />}
            iconOnly
            data-test-id="search-label"
          />
        )}
        renderMediumAndUp={() => (
          <FilterButton
            onClick={searchingSwitch.on}
            leftIcon={<SearchIcon />}
            label={__('Search', 'search')}
          />
        )}
      />
      {searchingSwitch.value ? (
        <form onSubmit={handleSearch} aria-label="form" role="search">
          <SearchInputContainer>
            <FakeInput>
              <label
                className="search-icon"
                htmlFor={'search'}
                aria-label="search"
                data-test-id="search-label"
              >
                <SearchIcon />
              </label>
              <input
                type="text"
                name="search"
                ref={inputRef}
                value={pendingSearch ?? ''}
                onChange={handleLiveSearch}
                data-test-id="search-input"
                size={5} // https://stackoverflow.com/questions/29470676/why-doesnt-the-input-element-respect-min-width
              />
              {searchStatus !== SearchBoxState.SEARCH_APPLIED ? (
                <IconButton
                  aria-label="search"
                  icon={<GoIcon />}
                  size={'large'}
                  css={{
                    $$iconColor:
                      searchStatus === SearchBoxState.NOT_SEARCHING
                        ? '$colors$black-alpha-10'
                        : '$colors$teal-500',
                  }}
                  testId="search-button"
                />
              ) : (
                <IconButton
                  aria-label={'Clear search'}
                  size="large"
                  icon={<ClearIcon />}
                  onClick={handleClear}
                  type="button"
                  testId="clear-search-button"
                />
              )}
            </FakeInput>
            <div>
              <Button
                presentation="tealTransparent"
                label={__('Cancel', 'cancel')}
                onClick={handleCancel}
                type="button"
                css={{
                  // https://jiveworld.slite.com/app/docs/cJrwuK4lOZ5rdw#dd6f7bfc ¯\_(ツ)_/¯
                  paddingRight: '0',
                  '@medium': {
                    paddingRight: '$$px',
                  },
                }}
              />
            </div>
          </SearchInputContainer>
        </form>
      ) : null}
    </>
  );
};
