import * as React from 'react';
import Cookies from 'js-cookie';
import { HStack, VStack } from 'naan/primitives/stack';

import { AppFactory } from 'app/app-factory';
import { ActionLink } from 'common/ui/action-link';
import { observer } from 'mobx-react';
import { createLogger } from '@common/log';
import { CurrentDateDevUI } from 'components/admin/current-date-dev-ui';
import { bugsnagNotify } from 'app/notification-service';
import { UserManager } from '@core/models/user-manager';
import { StorageCanaryUI } from 'components/admin/storage-canary-ui';
import { MediumHeading } from '@naan/primitives/text';
import { appConfig } from '@app/config';
import { embeddedMode } from '@app/app-util';
import { nativeWakeLockSupported } from '@core/lib/app-util';
import { USER_TOKEN_COOKIE_KEY } from '@core/models/user-manager/user-manager';
import { getAvailableTranslations } from '@core/lib/localization';
import {
  getInstallationId,
  resetFirstVisitState,
} from '@core/models/installation-id';
import { LocaleCode } from '@utils/util-types';
import { Currency, allCurrencies } from '@cas-shared/cas-types';

const log = createLogger('dev-tools');

(window as any).jsCookies = Cookies; // console convenience

const MasalaAdminToggle = observer(() => {
  const { userManager } = AppFactory.root;
  // intentionally skipping the await
  const toggle = () => userManager.userData.toggleMasalaAdmin();
  return (
    <HStack gap={'small'}>
      Masala admin (ugly debug) mode: {String(userManager.showUglyDevUI)}
      &nbsp;
      <ActionLink onPress={toggle}>[toggle]</ActionLink>
    </HStack>
  );
});

const MiscToggle = () => {
  const [dirty, setDirty] = React.useState(0);
  const { root } = AppFactory;
  const { userManager } = root;

  log.info(`MiscToggle dirty: ${dirty}`);
  return (
    <>
      {/* // piggy back the old loggly toggle component for the dirty state handling */}
      <HStack gap={'small'}>
        Purchase flow disabled:&nbsp;
        {String(userManager.purchaseFlowDisabled)}
        &nbsp;
        <ActionLink
          onPress={() => {
            appConfig.forceNoPurchase = !appConfig.forceNoPurchase;
            setDirty(Date.now());
          }}
        >
          [toggle forced flag]
        </ActionLink>
      </HStack>

      <HStack gap={'small'}>
        Show error details:&nbsp;
        {String(appConfig.showErrorDetails)}
        &nbsp;
        <ActionLink
          onPress={() => {
            appConfig.showErrorDetails = !appConfig.showErrorDetails;
            setDirty(Date.now());
          }}
        >
          [toggle]
        </ActionLink>
      </HStack>

      <HStack gap={'small'}>
        Show warning alerts:&nbsp;
        {String(appConfig.showWarningAlerts)}
        &nbsp;
        <ActionLink
          onPress={() => {
            appConfig.showWarningAlerts = !appConfig.showWarningAlerts;
            setDirty(Date.now());
          }}
        >
          [toggle]
        </ActionLink>
      </HStack>
    </>
  );
};

const ClassroomToggle = observer(() => {
  const { userManager } = AppFactory.root;
  const toggle = () => userManager.toggleClassroomActivation();
  return (
    <HStack gap={'small'}>
      Teacher mode: {String(userManager.classroomEnabled)}&nbsp;
      <ActionLink onPress={toggle}>[toggle]</ActionLink>
    </HStack>
  );
});

const ExportVocabToggle = observer(() => {
  const { userSettings } = AppFactory.root.userManager.userData;
  const toggle = () => userSettings.toggleVocabExport();
  return (
    <HStack gap={'small'}>
      Show vocab export: {String(userSettings.showVocabListExportOption)}&nbsp;
      <ActionLink onPress={toggle}>[toggle]</ActionLink>
    </HStack>
  );
});

const NoSleepHackToggle = observer(() => {
  const { localState } = AppFactory.root;
  const toggle = () => localState.toggleKeepAwakeHack().catch(bugsnagNotify);
  return (
    <HStack gap={'small'}>
      {localState.keepAwakeHackApplicable ? (
        <>
          Don't hack keep awake: {String(localState.dontHackKeepAwake)}
          &nbsp;
          <ActionLink onPress={toggle}>[toggle]</ActionLink>
        </>
      ) : (
        <>
          Keep awake mode: {embeddedMode() ? 'embedded ' : null}
          {nativeWakeLockSupported() ? 'navigator api ' : null}
        </>
      )}
    </HStack>
  );
});

const FirebaseErrorToggle = observer(() => {
  const { localState } = AppFactory.root;
  return (
    <HStack gap={'small'}>
      Force firebase error:&nbsp;
      {String(localState.forceFirebaseError)}
      &nbsp;
      <ActionLink onPress={() => localState.toggleForceFirebaseError()}>
        [toggle]
      </ActionLink>{' '}
      &nbsp; (connection status: {AppFactory.firebaseConnection.status})
    </HStack>
  );
});

export const selectNextAvailableLocale = () => {
  const { root } = AppFactory;
  // const { userData } = root.userManager;
  // const locale = userData.selectedL1;
  const locale = root.locale;
  const locales = getAvailableTranslations() as LocaleCode[];

  const currentIndex = locales.indexOf(locale);
  const nextIndex = (currentIndex + 1) % locales.length;
  // userData.selectL1(locales[nextIndex]).catch(bugsnagNotify);
  root.overrideL1(locales[nextIndex]);
};

export const LocaleToggle = observer(() => {
  const { root } = AppFactory;
  // const { userData } = root.userManager;
  // const locale = userData.selectedL1;
  const { locale } = root;
  const locales = getAvailableTranslations();

  return (
    <HStack gap={'small'}>
      active L1:
      <select
        value={locale}
        onChange={event => {
          // userData .selectL1(event.target.value as LocaleCode) .catch(bugsnagNotify);
          root.overrideL1(event.target.value as LocaleCode);
        }}
      >
        {locales.map(locale => (
          <option key={locale} value={locale}>
            {locale}
          </option>
        ))}
      </select>
    </HStack>
  );
});

export const CurrencySelector = observer(() => {
  const { root } = AppFactory;
  const { currency } = root;
  const currencies = allCurrencies();

  const accountCurrency = root.userManager.nodeAccountData?.currency;
  if (!!accountCurrency) {
    return <HStack gap={'small'}>account currency: {accountCurrency}</HStack>;
  }

  return (
    <HStack gap={'small'}>
      currency:
      <select
        value={currency}
        onChange={event => {
          root.selectCurrency(event.target.value as Currency);
        }}
      >
        {currencies.map(cur => (
          <option key={cur} value={cur}>
            {cur}
          </option>
        ))}
      </select>
    </HStack>
  );
});

const StatusBarToggle = observer(() => {
  const { localState } = AppFactory.root;
  return (
    <HStack gap={'small'}>
      Show debug status bar:&nbsp;
      {String(localState.debugStatusBarEnabled)}
      &nbsp;
      <ActionLink onPress={() => localState.toggleDebugStatusBar()}>
        [toggle]
      </ActionLink>
    </HStack>
  );
});

const PiToggle = observer(() => {
  const { root } = AppFactory;

  const handleToggle = () => {
    root.togglePiMenu();
  };

  return (
    <HStack gap={'small'}>
      Force hide Pi menu:&nbsp;
      {String(root.forceHidePiMenu)}
      &nbsp;
      <ActionLink onPress={handleToggle}>[toggle]</ActionLink>
    </HStack>
  );
});

const DailySubscriptionToggle = observer(() => {
  const { root } = AppFactory;

  const handleToggle = () => {
    root.toggleDailySubscription();
  };

  return (
    <HStack gap={'small'}>
      Show Daily pricing card:&nbsp;
      {String(root.dailySubscriptionEnabled)}
      &nbsp;
      <ActionLink onPress={handleToggle}>[toggle]</ActionLink>
    </HStack>
  );
});

const SoundbitesToggle = () => {
  const [dirty, setDirty] = React.useState(0);

  const handleToggle = () => {
    appConfig.soundbites.disabled = !appConfig.soundbites.disabled;
    setDirty(Date.now());
  };

  log.info(`SoundbitesToggle dirty: ${dirty}`);
  return (
    <HStack gap={'small'}>
      Soundbites:&nbsp;
      {appConfig.soundbites.disabled ? 'disabled' : 'enabled'}
      &nbsp;
      <ActionLink onPress={handleToggle}>[toggle]</ActionLink>{' '}
    </HStack>
  );
};

export const path = 'toggles';
export const label = 'Toggles';

export const Component = observer(() => {
  const { root } = AppFactory;
  const { userManager } = root;

  const { accountData, userData } = userManager;

  const userManagerOtherData = userManager.snapshot as UserManager;
  userManagerOtherData.accountData = undefined;
  userManagerOtherData.userData = undefined;

  const authCookieData = Cookies.get(USER_TOKEN_COOKIE_KEY);

  return (
    <VStack>
      <MediumHeading>Toggles</MediumHeading>
      <PiToggle />
      <DailySubscriptionToggle />
      <SoundbitesToggle />
      <FirebaseErrorToggle />
      <StatusBarToggle />
      <ClassroomToggle />
      <ExportVocabToggle />
      <NoSleepHackToggle />
      <MasalaAdminToggle />
      <MiscToggle />
      <CurrentDateDevUI />
      <StorageCanaryUI />
      {/* <ServerAuthCookieUI /> */}
      <p>jw-user-token cookie data: {String(authCookieData)}</p>

      <p>
        Mailing list - opt-in: {String(accountData.mailingListOptIn)}{' '}
        <ActionLink onPress={() => userManager.toggleMailingListOptIn()}>
          [toggle]
        </ActionLink>
        {' - '}
        prompt needed: {String(accountData.mailingListPromptNeeded)}, prompt
        enabled: {String(userData.mailingListPromptEnabled)}
        <br />
        installation id: {getInstallationId()} -{' '}
        <ActionLink onPress={() => resetFirstVisitState()}>
          [reset first visited state]
        </ActionLink>
      </p>
    </VStack>
  );
});
