import * as React from 'react';
import * as Onboarding from './components';
// import { PlainMarkdown } from '@naan/primitives/text';
import { styled } from '@stitches/react';
import { observer } from 'mobx-react';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import { OnboardingDialog } from './onboarding-dialogs';
import { unwidow } from '@utils/string-utils';

import __ from 'core/lib/localization';

import illustration from './assets/pause-for-help-illustration.png';
import { usePlayerModel } from 'player/views/player-model-context';

const Image = styled(Onboarding.Image, {
  width: 80,
});

const Content = styled(Onboarding.Content, {
  textAlign: 'center',
});

export const PauseForHelpOnboardingDialog = observer(() => {
  const { showPauseForHelpDialog, dismissPauseForHelpDialog } =
    OnboardingService.instance;

  const model = usePlayerModel();

  if (!showPauseForHelpDialog) {
    return null;
  }

  return (
    <OnboardingDialog
      body={
        <Onboarding.Container>
          <Image src={illustration} />
          <Content>
            {unwidow(
              __(
                'If you need help with a sentence, tap Pause.',
                'ifYouNeedHelpWithASentenceTapPause'
              )
            )}
          </Content>
        </Onboarding.Container>
      }
      onResolve={() => dismissPauseForHelpDialog(model)}
    />
  );
});
