import { styled } from '@naan/stitches.config';
import { profileHomePath } from 'components/nav/path-helpers';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useReform } from './use-reform';
import { AppFactory } from '@app/app-factory';
import { formatDate } from '@utils/format-date';
import { Button } from '@naan/primitives/button';
import { AvatarIcon } from '@naan/icons/avatar-icon';
import { useSwitch2 } from '@naan/hooks/use-switch-2';

import __, { pickLocalizedValue } from '@core/lib/localization';

const Wrapper = styled('div', {
  paddingTop: 48,
  '& .copy': {
    marginBottom: -80,
    padding: 16,
    '&  h2': {
      textStyle: 'large-heading',
      marginBottom: 8,
    },
  },

  '& #my-reform': {
    margin: '0 auto',

    width: 'min(100%, 496px)',
    // padding: '48px 32px',
  },

  '& .account-button-container': {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 40,
  },
});

// extend window with Reform
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    Reform: any;
  }
}

// function sleepPromise(ms: number) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

const reformSettings = () => {
  return pickLocalizedValue({
    en: {
      formUrl: 'https://forms.reform.app/QVIesK/exit-survey/z9pdpw',
      targetId: '#my-reform',
      emailFieldName: 'e293485e-8099-4b87-975f-13e3b5ecce33',
      userIdFieldName: '2017c041-f21e-4b38-91cc-a906322c2c4c',
    },
    pt: {
      formUrl: 'https://forms.reform.app/QVIesK/exit-survey-pt/z9pdpw',
      targetId: '#my-reform',
      emailFieldName: '7e16c893-20eb-48fc-a9f1-0472f0a49448',
      userIdFieldName: '0334b5f8-1b2a-4aac-b1e8-c5a67ea6f973',
    },
  });
};

export const ExitSurveyScreen = () => {
  const { fullAccessUntil } = AppFactory.root.userManager.membership;
  const showGreeting = useSwitch2(true);

  const navigate = useNavigate();

  const onFormSubmitted = React.useCallback(async () => {
    // navigate(learnHomePath());
    showGreeting.off();
  }, [showGreeting]);

  useReform({
    ...reformSettings(),
    onFormComplete: onFormSubmitted,
  });

  return (
    <Wrapper>
      {showGreeting.value ? (
        <div className="copy">
          <h2>{__('Sorry to see you go', 'sorryToSeeYouGo')}</h2>
          <p className="intro">
            {__(
              "You'll still have full access until %{date}. If you have a moment, please let us know why you cancelled.",
              'youllStillHaveFullAccess',
              { date: formatDate(fullAccessUntil) }
            )}
          </p>
        </div>
      ) : null}
      <div id="my-reform"></div>
      <div className="account-button-container">
        <Button
          leftIcon={<AvatarIcon />}
          presentation="grayLight"
          label={__('My account', 'myAccount')}
          onClick={() => {
            navigate(profileHomePath());
          }}
        />
      </div>
    </Wrapper>
  );
};
