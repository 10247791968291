import * as React from 'react';
import { observer } from 'mobx-react';
import {
  Container,
  Label,
  LeftSide,
  RightSide,
  Centered,
  Row,
  Value,
} from './components';
// import { Text } from 'naan/primitives/text';
import { LargeGap } from '@naan/primitives/spacer/gaps';
import { formatDate } from 'utils/format-date';
import { HStack, VStack } from 'naan/primitives/stack';
import { AppFactory } from 'app/app-factory';
import { styled } from 'naan/stitches.config';
import { AlertIcon } from '@naan/icons/alert-icon';
import { alertSevereError } from '@app/notification-service';
import { LocaleCode } from '@utils/util-types';
import { CancelButton } from './buttons/cancel-button';
import { ResumeButton } from './buttons/resume-button';
import { SwitchToYearlyButton } from './buttons/switch-to-yearly-button';
import { Button } from '@naan/primitives/button';

import __ from 'core/lib/localization';

const FailedTag = styled('div', {
  color: '$red-500',
  display: 'flex',
  gap: 2,
});

export const useStripePortal = ({ l2 }: { l2: LocaleCode }) => {
  const { userManager } = AppFactory.root;

  const gotoStripePortal = React.useCallback(() => {
    userManager
      .membershipL2(l2)
      .createStripePortalSession()
      .then(result => {
        // console.log(`stripe portal: ${JSON.stringify(result)}`);
        const { url } = result;
        window.location.href = url;
      })
      .catch(error => {
        alertSevereError({ error, note: 'gotoStripePortal' });
      });
  }, [userManager, l2]);

  return { gotoStripePortal };
};

export const FullAutoRenew = observer(({ l2 }: { l2: LocaleCode }) => {
  const { userManager } = AppFactory.root;
  const membership = userManager.membershipL2(l2);
  const {
    statusKey,
    membershipTypeDisplay,
    autoRenewAmountDisplay,
    showSwitchPlanInterval,
    fullAccessUntil,
    pausedUntil,
    billingResumesOn,
    accessExpired,
    autoRenewFailed,
    paymentFailureMessage,
  } = membership;

  // const isSuspended = statusKey === 'suspended';
  const isPaused = statusKey === 'paused';
  const renewsOn = isPaused ? billingResumesOn : fullAccessUntil;

  const { gotoStripePortal } = useStripePortal({ l2 });

  return (
    <>
      <Container>
        <Row>
          <LeftSide>
            <Label>{__('Membership type', 'membershipType')}</Label>
            <Value>
              <HStack justify={'space'}>
                <VStack
                  css={{
                    maxWidth: '66%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {membershipTypeDisplay}
                  {/* ({statusKey}) */}
                </VStack>
              </HStack>
            </Value>
          </LeftSide>
          <RightSide>
            {showSwitchPlanInterval ? <SwitchToYearlyButton l2={l2} /> : null}
          </RightSide>
        </Row>

        {/* DEFERRED (always false for now) */}
        {isPaused ? (
          <Row>
            <Label>{__('Paused until', 'pausedUntil')}</Label>
            <Value>
              <HStack justify={'space'}>
                <VStack
                  css={{
                    maxWidth: '66%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {formatDate(pausedUntil)}
                </VStack>
                <ResumeButton l2={l2} />
              </HStack>
            </Value>{' '}
          </Row>
        ) : null}

        <Row>
          <Label>{__('Renews', 'renews')}</Label>
          <Value>
            <HStack justify={'space'}>
              {formatDate(renewsOn)}
              {accessExpired ? ' ' + __('(past due)', 'pastDueParens') : null}
            </HStack>
          </Value>
        </Row>

        <Row>
          <Label>{__('Renewal amount', 'renewalAmount')}</Label>
          <Value>{autoRenewAmountDisplay}</Value>
        </Row>

        <Row>
          <LeftSide>
            <Label>{__('Payment method', 'paymentMethod')}</Label>
            {autoRenewFailed ? (
              <Value>
                <FailedTag>
                  <AlertIcon />
                  <span>{__('Failed', 'failed')}</span>
                </FailedTag>
                {paymentFailureMessage ? <>{paymentFailureMessage}</> : null}
              </Value>
            ) : null}
          </LeftSide>
          <RightSide>
            <Button
              size="small"
              presentation={autoRenewFailed ? 'redLight' : 'grayLight'}
              onClick={gotoStripePortal}
              label={__('View / update', 'viewSlashUpdate')}
            />
          </RightSide>
        </Row>

        {isPaused ? null : (
          <Row>
            <Centered>
              <CancelButton l2={l2} />
            </Centered>
          </Row>
        )}
      </Container>
      <LargeGap />
    </>
  );
});
