import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { paletteKey } from '../../../study/views/study-palette';
import { PlainMarkdown } from '@naan/primitives/text';
// import { SimpleNotationsListPanel } from 'study/views/notations/simple-notations-list';
import { SentenceId } from '@tikka/basic-types';
import { SentenceExtrasGeneral } from './sentence-extras';
import { Button } from '@naan/primitives/button';
import __ from '@core/lib/localization';

const L2Wrapper = styled('div', {
  position: 'relative',
  borderRadius: '16px',
  transition: 'opacity .3s, background-color .15s, transform .3s',
  textStyle: 'serif',
  cursor: 'default',
  userSelect: 'none',

  '&:not(.current).visited:hover': {
    backgroundColor: paletteKey('$$sentence__backgroundColor_hover'),
  },

  '& .show-words-container': {
    position: 'absolute',
    right: '0',
    top: '-48px',
    zIndex: '1',
  },

  '.playing &.current .show-words-container': {
    transition: 'opacity ease-in 2s',
  },

  '&:not(.current) .show-words-container, &.hide-show-words-btn .show-words-container':
    {
      opacity: 0,
      visibility: 'hidden',
      pointerEvents: 'none',
    },

  // [`& ${UnredactButton}`]: {
  //   transition: 'opacity .3s',
  //   opacity: 0,
  //   pointerEvents: 'none',
  // },

  // [`&.expose-unredact-sentence ${UnredactButton}`]: {
  //   opacity: 1,
  //   pointerEvents: 'all',
  // },

  '& .l2-and-translation': {
    position: 'relative',
  },

  '& .l2': {
    padding: '13px 16px 15px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
  },

  '& > .card > .ext': {
    display: 'none',
    // display: 'grid',
    // gridTemplateRows: '0fr',
    // transition: 'grid-template-rows .12s',
    // '& > div': {
    //   gridRow: '1 / span 2',
    //   overflow: 'hidden',
    // },
  },

  '#script-scrolling-container:not(.playing) &.current > .card > .ext': {
    display: 'block',
    // gridTemplateRows: '1fr',
    // '& > div': {
    //   overflow: 'auto',
    // },
  },
  '&.current.pending-pause > .card > .ext': {
    display: 'block',
    // gridTemplateRows: '1fr',
    // '& > div': {
    //   overflow: 'auto',
    // },
  },

  '& .translation': {
    textStyle: 'small-text',
    padding: '12px 16px',
    borderRadius: '12px',
    color: paletteKey('$$translation__color'),
    width: 'calc(100% - 16px)',
    position: 'absolute',
    top: '50%',
    left: 'calc(100% + 8px)',
    transform: 'translateY(-50%)',
    opacity: '0',
    transition: 'right .3s, left .3s, opacity .3s, background-color .15s',
    zIndex: '-1',

    '@widerThanScript': {
      width: '100%',
    },
    '@playerMediumAndUp': {
      width: '384px',
      left: 'auto',
      right: '0',
    },
    '@playerLarge': {
      right: '-336px',
    },
  },

  '&.visited .translation': {
    color: '$$translation__color_visited',
  },

  '&.current .translation': {
    color: '$$translation__color_visited',
  },

  '&:not(.current).visited .translation:hover': {
    backgroundColor: paletteKey('$$sentence__backgroundColor_hover'),
  },

  '&.current': {
    backgroundColor: paletteKey('$$sentence__backgroundColor_current'),
    boxShadow: '0px 1px 2px rgb(0 0 0 / 20%)',
    '& .translation': {
      backgroundColor: paletteKey('$$sentence__backgroundColor_current'),
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
    },

    /// this makes the translation visible
  },

  '&.show-translations': {
    '& .translation': {
      opacity: 1,
    },
    '@playerSmall': {
      transform: 'translateX(calc(-100% + 8px))',
    },
    '@widerThanScript': {
      transform: 'translateX(calc(-50vw - 286px))',
      '& .translation': {
        left: 'calc(50vw + 286px)',
      },
    },
    '@playerMedium': {
      transform: 'translateX(-196px)',
      '& .translation': {
        left: 'auto',
        right: '-392px',
      },
    },
    '@playerLarge': {
      transform: 'translateX(0)',
      '& .translation': {
        left: 'auto',
        right: '-392px',
      },
    },
  },
});

const ShowWordsWrapper = styled('div', {
  padding: '8px 0',
  '& > .inner': {
    backgroundColor: paletteKey('$$sentence__backgroundColor_current'),
    boxShadow: '0px 1px 2px rgb(0 0 0 / 20%)',
    borderRadius: '20px',
  },
});

type WrapperProps = React.ComponentProps<typeof L2Wrapper>;

export const StyledSentence: React.FC<
  WrapperProps & {
    translation: string;
    sentenceId: SentenceId;
    onUnredact: () => void;
  }
> = ({ children, translation, sentenceId, onClick, onUnredact, ...props }) => {
  return (
    <L2Wrapper
      {...props}
      className={props.className + ' line script-sentence-container'}
    >
      <div className="show-words-container">
        <ShowWordsWrapper onClick={onUnredact}>
          <div className="inner">
            <Button
              label={__('Show words', 'showWords')}
              size="small"
              presentation="white"
            />
          </div>
        </ShowWordsWrapper>
      </div>
      <div className="card">
        <div className="l2-and-translation">
          <div className="l2" onClick={onClick}>
            {children}
          </div>
          <div className="translation" onClick={onClick}>
            <PlainMarkdown source={translation} />
          </div>
        </div>
        <div className="ext">
          <SentenceExtrasGeneral
            sentenceId={sentenceId}
            translation={translation}
          />
        </div>
      </div>
    </L2Wrapper>
  );
};
