import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { PageHeadingContainer, PageTitle } from './page-heading';
import { AppFactory } from '@app/app-factory';
import { observer } from 'mobx-react-lite';
import {
  hasPrimaryFilter,
  PrimaryFilterKeys,
} from '@core/models/story-manager/story-manager';
import { InProgressIcon } from '@naan/icons/in-progress-icon';
import { ClockIcon } from '@naan/icons/clock-icon';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { Responsive } from '@naan/primitives/responsive';
import { colors } from '@naan/tokens/colors';
import { isClassroomFilterKey } from '@core/lib/constants/vars';
import { EduExtraLargeIcon, EduIcon } from '@naan/icons/edu-icon';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { useNavigate } from 'react-router-dom';
import { ChevronDownExtraSmallIcon } from '@naan/icons/chevron-down-icon';
import { Button } from '@naan/primitives/button';
import { HSpacer } from '@naan/primitives/spacer';
import { CheckmarkIcon } from '@naan/icons/checkmark-icon';
import { storiesPrimaryFiltered } from 'components/nav/path-helpers';
import { Divider } from '@naan/primitives/divider';
import { ReadyToPlayIcon } from '@naan/icons/ready-to-play-icon';

import __ from 'core/lib/localization';

const HeadingContainer = styled(PageHeadingContainer, {
  justifyContent: 'space-between',
  '@medium': {
    justifyContent: 'flex-start',
  },
  alignContent: 'center',
  '& >.show-all-buttton': {
    marginLeft: '$space$2',
    '@medium': {
      marginLeft: '$space$4',
    },
  },
  '& [type=button]': {
    appearance: 'none',
  },
});

const TriggerWrapper = styled('div', {
  overflow: 'hidden',
});

const TriggerContainer = styled('div', {
  display: 'flex',
  width: 'fit-content',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'default',
  maxWidth: '100%',
});

const Title = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  transition: 'color .15s',
  '&:hover': {
    color: '$teal-500',
  },
  '& > .chevron-icon': {
    transform: 'translateY($space$0)',
  },
});

const Label = styled('span', {
  display: 'inline-block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const IconWrapper = styled('div', {
  marginRight: '6px',
  marginBottom: '-$space$0',
});

const Icon = observer(({ filterKey }: { filterKey: string }) => {
  let icon: {};
  let mediumIcon: {};
  let color;

  if (isClassroomFilterKey(filterKey)) {
    color = colors['gray-400'];
    icon = <EduIcon />;
    mediumIcon = <EduExtraLargeIcon />;
  } else {
    switch (filterKey) {
      case PrimaryFilterKeys.IN_PROGRESS:
        color = colors['teal-500'];
        icon = <InProgressIcon />;
        mediumIcon = <InProgressIcon width={32} height={32} />;
        break;
      case PrimaryFilterKeys.UNPLAYED:
        color = colors['gray-400'];
        icon = <ReadyToPlayIcon />;
        mediumIcon = <ReadyToPlayIcon width={32} height={32} />;
        break;
      case PrimaryFilterKeys.QUEUED:
        color = colors['orange-500'];
        icon = <ClockIcon />;
        mediumIcon = <ClockIcon width={32} height={32} />;
        break;
      case PrimaryFilterKeys.COMPLETED:
        color = colors['gray-400'];
        icon = <CheckmarkCircleIcon />;
        mediumIcon = <CheckmarkCircleIcon width={32} height={32} />;
        break;
      default:
        return null;
    }
  }

  return (
    <IconWrapper css={{ color: color }}>
      <Responsive
        renderDefault={() => icon}
        renderMediumAndUp={() => mediumIcon}
      />
    </IconWrapper>
  );
});

const Trigger = ({
  primaryFilterKey,
  title,
}: {
  primaryFilterKey: string;
  title: string;
}) => (
  <TriggerContainer>
    <Icon filterKey={primaryFilterKey} />
    <PageTitle
      title={
        <Title>
          <Label>{title}</Label>
          <HSpacer size={1} />
          <div className="chevron-icon">
            <ChevronDownExtraSmallIcon />
          </div>
        </Title>
      }
    />
  </TriggerContainer>
);

const MenuItemIcon = observer(({ filterKey }: { filterKey: string }) => {
  if (isClassroomFilterKey(filterKey)) {
    return <EduIcon />;
  }

  switch (filterKey) {
    case PrimaryFilterKeys.UNPLAYED:
      return <ReadyToPlayIcon />;
    case PrimaryFilterKeys.IN_PROGRESS:
      return <InProgressIcon />;
    case PrimaryFilterKeys.QUEUED:
      return <ClockIcon />;
    case PrimaryFilterKeys.COMPLETED:
      return <CheckmarkCircleIcon />;
    default:
      return null;
  }
});

const FilterMenuItem = ({
  isSelected,
  label,
  filterKey,
}: {
  isSelected: boolean;
  label: string;
  filterKey: string;
}) => {
  const showCheckmarkIcon = isSelected && filterKey !== PrimaryFilterKeys.ALL;
  const navigate = useNavigate();
  return (
    <MenuItem
      isSelected={isSelected}
      label={label}
      action={() => navigate(storiesPrimaryFiltered(filterKey))}
      leftIcon={<MenuItemIcon filterKey={filterKey} />}
      rightIcon={showCheckmarkIcon ? <CheckmarkIcon /> : null}
    />
  );
};

export const PageHeadingWithStoryFilters = observer(
  ({ primaryFilterKey }: { primaryFilterKey: PrimaryFilterKeys }) => {
    const { userManager } = AppFactory.root;

    const title = userManager.primaryFilterLabel(primaryFilterKey);
    const primaryFilterLabels = userManager.primaryFilterLabels;

    const navigate = useNavigate();

    const _hasPrimaryFilter = hasPrimaryFilter(primaryFilterKey);

    return (
      <>
        <HeadingContainer>
          <Menu
            align="start"
            offset={8}
            trigger={
              <TriggerWrapper>
                <Trigger title={title} primaryFilterKey={primaryFilterKey} />
              </TriggerWrapper>
            }
          >
            {Object.entries(primaryFilterLabels).map(([key, label]) => (
              <FilterMenuItem
                isSelected={key === primaryFilterKey}
                label={label}
                filterKey={key}
              />
            ))}
          </Menu>
          {_hasPrimaryFilter ? (
            <div className="show-all-buttton">
              <Button
                presentation={'secondary'}
                size={'small'}
                label={__('Show all', 'showAll')}
                onClick={() =>
                  navigate(storiesPrimaryFiltered(PrimaryFilterKeys.ALL))
                }
              />
            </div>
          ) : null}
        </HeadingContainer>
        {_hasPrimaryFilter ? <Divider /> : null}
      </>
    );
  }
);
