import * as React from 'react';
import { OnboardingService, TipKey } from '@app/onboarding/onboarding-service';
import { styled } from '@naan/stitches.config';
import { keyframes } from '@stitches/react';
import { observer } from 'mobx-react';

const pulse = keyframes({
  '0%': { transform: 'scale(0.1)', opacity: 1 },
  '100%': { transform: 'scale(1)', opacity: 0 },
});

export const Hotspot = styled('span', {
  position: 'relative',
  $$offsetX: '-1px',
  $$offsetY: '14px',
  // background: 'yellow',
  variants: {
    active: {
      true: {
        '&::before': {
          content: '',
          position: 'absolute',
          top: '$$offsetY',
          right: '$$offsetX',
          width: 8,
          aspectRatio: '1/1',
          borderRadius: '50%',
          backgroundColor: '$colors$red-500',
          pointerEvents: 'none',
        },
        '&::after': {
          content: '',
          position: 'absolute',
          top: 'calc($$offsetY - 12px)', // 'calc($$offsetY - 4px)
          right: 'calc($$offsetX - 12px)', // 'calc($$offsetX - 4px)
          width: 32,
          aspectRatio: '1/1',
          borderRadius: '50%',
          border: '4px solid $colors$red-500',
          transform: 'scale(0.1)',
          opacity: 1,
          animation: `${pulse} 1s ease-out infinite`,
          pointerEvents: 'none',
        },
      },
    },
  },
});

export const ControlledHotspot = observer(
  ({
    renderContent,
    keyName: key,
    disabled = false,
  }: {
    renderContent: (dismissCallback: () => void) => React.ReactNode;
    keyName: TipKey;
    disabled?: boolean;
  }) => {
    const dismissed = OnboardingService.instance.isDismissed(key);
    const active = !dismissed && !disabled;
    const dismissCallback = React.useCallback(() => {
      if (active) {
        OnboardingService.instance.dismiss(key);
      }
    }, [key, active]);

    if (!active) {
      <>{renderContent(() => {})}</>;
    }

    return <Hotspot active={active}>{renderContent(dismissCallback)}</Hotspot>;
  }
);
