import * as React from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { namedStyled, styled } from '@naan/stitches.config';
import { Story } from '@core/models/story-manager';
import { StoryCardMeta } from './story-card-meta';
import { MarkSimple } from '@naan/primitives/text';
import { VolumeTagLinks } from 'components/volume-layouts/volume-tag-links';
import { StoryTitle } from './story-title';
import { StoryChapterCard } from './story-chapter-card';
import { StoryOverflowMenu } from 'components/story-detail/story-overflow-menu';
// import { StoryCardAssignment } from './story-card-assignment';
import { Assignment } from '@core/models/user-manager/assignment';
import { StoryCardMasalaAdmin } from 'components/admin/masala-admin';
import { VSpacer } from '@naan/primitives/spacer';
import classNames from 'classnames';
import { StoryCardAssignment } from './story-card-assignment';
import { Image } from '@naan/primitives/image';
import {
  learnStoriesPath,
  learnStorySlugPath,
} from 'components/nav/path-helpers';
// import { SoundbiteFilterValues } from '@core/models/story-manager/story';

const Link = styled(RouterLink, {
  color: 'inherit',
  fontSize: 'inherit',
  fontFamily: 'inherit',
  textDecoration: 'none',
});

const Wrapper = namedStyled('storyCardWrapper', 'div', {
  $$thumbSize: '80px',
  display: 'grid',
  gridTemplateColumns: '$$thumbSize 1fr',
  gap: 16,
  paddingTop: 16,

  '& > .thumbnail': {
    img: {
      width: '$$thumbSize',
      aspectRatio: 1,
      objectFit: 'cover',
      borderRadius: 8,
      marginBottom: 16,
      outline: '1px solid rgba(0,0,0,.06)',
      outlineOffset: -1,
    },
  },

  '& > .content': {
    minWidth: 0, // stops assignment buttons pushing .content wide
    '& > .header': {
      position: 'relative',
      paddingRight: 24,
      '& > .menu': {
        position: 'absolute',
        right: -8,
        top: -8,
      },
    },
    '& > .body': {
      textStyle: 'small-text',
      color: '$textSecondary',
      '&.truncate': {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '3',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },

  '@small': {
    $$thumbSize: '112px',
  },
  '@extraLarge': {
    $$thumbSize: '132px',
  },
});

export const StoryCard = observer(
  ({
    story,
    truncateDescription = false,
    assignment = undefined,
  }: {
    story: Story;
    truncateDescription?: boolean;
    assignment?: Assignment;
  }) => {
    if (!story) {
      return null;
    }

    const [queryParams] = useSearchParams();

    /// this next lines of code allow us to link directly to the soundbites
    /// when one of the sb filters is used.
    /// it's very unclean to couple this component to the url but it's the easiest way to do it.
    const sbFilterActive = queryParams.get('filter_type') === 'sb';

    // const completedSbOnly =
    //   queryParams.get('filter_value') ===
    //   SoundbiteFilterValues.withCompletedFn();

    const pathSuffix =
      sbFilterActive && story.hasCompletedSoundbites ? '?soundbites=1' : '';

    const permalink = learnStorySlugPath(story.slug + pathSuffix);

    const { title, progressMayBeNull, listImageUrl } = story;

    if (assignment === undefined) {
      assignment = story.joinedClassroomAssignment;
    }

    const currentChapter = progressMayBeNull?.currentChapter; // only present for STARTED status stories

    return (
      <>
        <Wrapper>
          <div className="thumbnail">
            <Link to={permalink}>
              <Image
                // className="thumbnail"
                src={listImageUrl}
                alt={title}
                /// we use style instead of `css` because `css` would generate a new class name behind the scenes.
                /// We want actual inline styles.
                style={{ backgroundColor: story.themeColor }}
              />
            </Link>
          </div>
          <div className="content">
            <header className="header">
              <Link to={permalink}>
                <StoryTitle story={story} />
              </Link>
              <StoryCardMeta story={story} />
              <div className="menu">
                <StoryOverflowMenu
                  story={story}
                  presentation="grayTransparent"
                  cardFlavor
                />
              </div>
            </header>
            {currentChapter ? (
              <>
                <StoryChapterCard story={story} viaDetail />
                <VSpacer size={4} />
              </>
            ) : (
              <>
                <div
                  className={classNames('body', {
                    truncate: truncateDescription,
                  })}
                >
                  <MarkSimple source={story.tagline} />
                </div>

                <VSpacer size={3} />
                <VolumeTagLinks
                  tags={story.allTagsExceptShorts}
                  linkPrefix={learnStoriesPath()}
                />
                <VSpacer size={4} />
              </>
            )}
            {assignment ? (
              <>
                <StoryCardAssignment assignment={assignment} />
                <VSpacer size={4} />
              </>
            ) : null}
          </div>
        </Wrapper>
        <StoryCardMasalaAdmin story={story} />
      </>
    );
  }
);
