import * as React from 'react';
// import { observer } from 'mobx-react';
import { namedObserver } from 'lib/named-observer';
import { Story } from '@core/models/story-manager/story';
import { track } from '@app/track';
import { StoryInterstitialSteps } from './story-interstitial-steps';
import { StoryInterstitialInitial } from './story-interstitial-initial';

type StoryViews = 'initial' | 'steps';

const INITIAL_VIEW = 'initial';
// const INITIAL_VIEW = 'steps'; //temp

export const StoryInterstitial = namedObserver(
  'StoryInterstitial',
  ({ story }: { story: Story }) => {
    const [currentView, setCurrentView] =
      React.useState<StoryViews>(INITIAL_VIEW);
    const gotoNextScreen = React.useCallback(() => {
      setCurrentView('steps');
    }, []);

    React.useEffect(() => {
      track('onboarding__interstitial_shown', { stage: 'story' });
    }, []);

    if (currentView === 'initial') {
      return (
        <StoryInterstitialInitial
          story={story}
          onButtonClick={gotoNextScreen}
        />
      );
    }

    return <StoryInterstitialSteps story={story} />;
  }
);
