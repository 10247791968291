import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { ChapterCatalogData } from '@core/models/catalog';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { IconButton } from '@naan/primitives/button';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { ellipsis } from '@utils/string-utils';
import { observer } from 'mobx-react';
import { useSwitch2 } from '@naan/hooks/use-switch-2';

import __ from '@core/lib/localization';

const RestartStudyConfirmationDialog = ({
  onDismiss,
  okAction,
}: {
  onDismiss: () => void;
  okAction: () => void;
}) => {
  const handleOkAndDismiss = React.useCallback(() => {
    onDismiss();
    okAction();
  }, [okAction, onDismiss]);

  return (
    <Dialog.Container open={true} onDismiss={onDismiss}>
      <Dialog.Heading>
        {__('Restart study from here', 'restartStudyFromHere')}
      </Dialog.Heading>
      <Dialog.Body>
        {__(
          'This will move you back to the start of this chapter for studying.',
          'thisWillMoveYouBackToTheStart'
        )}
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          presentation={'cancel'}
          label={__('Cancel', 'cancel')}
          data-test-id="okButton"
          onClick={onDismiss}
        />
        <Dialog.Button
          presentation={'teal'}
          label={__('Restart', 'restart')}
          data-test-id="okButton"
          onClick={handleOkAndDismiss}
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};

export const ChapterReviewOverflowMenu = observer(
  ({
    chapter,
    inReviewMode,
  }: {
    chapter: ChapterCatalogData;
    inReviewMode?: boolean;
  }) => {
    const dialogSwitch = useSwitch2(false);

    return (
      <>
        <Menu
          align="end"
          trigger={
            <IconButton
              presentation="grayTransparent"
              icon={<OverflowIcon />}
              testId={`chapter-list-overflow-${chapter.sortingRef}`}
            />
          }
        >
          <MenuItem
            label={
              __('Restart study from here', 'restartStudyFromHere') + ellipsis
            }
            action={() => {
              window.setTimeout(() => {
                dialogSwitch.on();
              }, 100);
              // maybePresentEndOfStoryCtaDialog();
            }}
          />
          {inReviewMode ? (
            <MenuItem
              label={__('Exit review', 'exitReview')}
              action={() => {
                chapter.story.lastChapter.resumeStudy();
              }}
            />
          ) : null}
        </Menu>
        {dialogSwitch.value ? (
          <RestartStudyConfirmationDialog
            okAction={() => {
              chapter.restartChapter();
              dialogSwitch.off();
            }}
            onDismiss={() => {
              dialogSwitch.off();
            }}
          />
        ) : null}
      </>
    );
  }
);
